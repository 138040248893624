<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main" id="resumo">
    <v-container class="d-flex justify-center">
      <div class="d-flex justify-center">
        <v-form class="form-size" ref="form">
          <v-row>
            <v-col cols="6" md="6">
              <v-card class="mx-auto">
                <v-card-text>
                  <h4 class="dark--text text--lighten-1">Nome do Proponente 1</h4>
                  <div v-if="respostaAssino[7].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[7].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[7].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[7].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[7].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[7].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[7].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[7].currentValue }}✅
                    </h4>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
           <v-col cols="6" md="6">
            <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">CPF do Proponente 1</h4>
        <div v-if="respostaAssino[0].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[0].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[0].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[0].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[0].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[0].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[0].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[0].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

            <v-col cols="6" md="6">
            <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Nome do Proponente 2</h4>
        <div v-if="respostaAssino[8].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[8].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[8].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[8].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[8].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[8].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[8].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[8].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

          <v-col cols="6" md="6">
            <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">CPF do Proponente 2</h4>
        <div v-if="respostaAssino[1].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[1].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[1].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[1].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[1].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[1].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[1].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[1].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>
          </v-row>
        
        <v-row>
          
          <v-col cols="3" md="3">
            <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Situação da análise</h4>
        <div v-if="respostaAssino[15].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[15].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[15].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[15].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[15].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[15].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[15].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[15].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
            <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Valor do imóvel</h4>
        <div v-if="respostaAssino[18].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[18].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[18].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[18].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[18].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[18].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[18].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[18].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Valor do financiamento</h4>
        <div v-if="respostaAssino[17].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[17].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[17].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[17].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[17].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[17].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[17].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[17].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Subsídio</h4>
        <div v-if="respostaAssino[16].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[16].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[16].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[16].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[16].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[16].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[16].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[16].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

        </v-row>

        <v-row>
         
          <v-col cols="2" md="2">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Possui direito a usar FGTS?</h4>
        <div v-if="respostaAssino[11].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[11].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[11].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[11].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[11].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[11].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[11].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[11].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="2" md="2">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">FGTS</h4>
        <div v-if="respostaAssino[5].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[5].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[5].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[5].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[5].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[5].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[5].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[5].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

          <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Valor total da proposta</h4>
        <div v-if="respostaAssino[21].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[21].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[21].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[21].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[21].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[21].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[21].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[21].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="2" md="2">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Prazo (meses)</h4>
        <div v-if="respostaAssino[12].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[12].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[12].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[12].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[12].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[12].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[12].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[12].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Valor da taxa de juros</h4>
        <div v-if="respostaAssino[20].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[20].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[20].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[20].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[20].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[20].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[20].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[20].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>
        </v-row>

        <v-row>
          <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Prestação aprovada</h4>
        <div v-if="respostaAssino[19].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[19].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[19].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[19].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[19].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[19].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[19].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[19].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Renda bruta familiar</h4>
        <div v-if="respostaAssino[13].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[13].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[13].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[13].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[13].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[13].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[13].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[13].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Possui direito ao redutor na taxa?</h4>
        <div v-if="respostaAssino[10].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[10].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[10].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[10].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[10].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[10].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[10].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[10].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Enquadramento</h4>
        <div v-if="respostaAssino[4].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[4].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[4].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[4].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[4].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[4].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[4].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[4].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>
        </v-row>


        <v-row>

          <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Sistema de amortização</h4>
        <div v-if="respostaAssino[14].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[14].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[14].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[14].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[14].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[14].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[14].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[14].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Indexador</h4>
        <div v-if="respostaAssino[6].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[6].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[6].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[6].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[6].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[6].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[6].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[6].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Data da avaliação</h4>
        <div v-if="respostaAssino[2].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[2].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[2].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[2].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[2].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[2].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[2].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[2].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

     <v-col cols="3" md="3">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Data de vencimento</h4>
        <div v-if="respostaAssino[3].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[3].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[3].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[3].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[3].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[3].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[3].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[3].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>

        </v-row>
        <v-row>
          <v-col cols="12" md="12">
      <v-card
      class="mx-auto">
      <v-card-text>
        <h4 class="dark--text text--lighten-1">Observações ou pendências</h4>
        <div v-if="respostaAssino[9].correct === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[9].originalValue }}✅
                    </h4>
                  </div>
                  <div v-else-if="respostaAssino[9].overwritten === false" style="text-align: center;">
                      <h4 style="text-align: center;" class="red--text text--lighten-1">
                        {{ respostaAssino[9].currentValue }}
                      </h4>
                      👇
                      <h4 style="text-align: center;" class="green--text text--lighten-1">
                        {{ respostaAssino[9].originalValue }}
                      </h4>
                      <div v-if="respostaAssino[9].originalValue === ''" style="text-align: center;">
                      Não existe informaçãoes para comparação ⚠
                    </div>
                  </div>
                  <div v-if="respostaAssino[9].overwritten === true">
                    <h4 style="text-align: center;" class="primary--text text--lighten-1">
                      {{ respostaAssino[9].currentValue }}✅
                    </h4>
                  </div>
      </v-card-text>
    </v-card>
     </v-col>
        </v-row> 
        </v-form>
      </div>
    </v-container>

    <!--AUTOFINANCIAMENTO-->
  </v-container>
</template>

<script>
// noinspection ES6CheckImport

import { hide } from "yargs";

export default {
  name: "RespostaAssinoResumo",
  props: ["respostaAssino"],

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  })
};
</script>

<style scoped src="./caixaqui-style.css"></style>
<style scoped>
.resumo-list {
  background-color: #f0f0f0;
}

.no-border-right {
  border-right: none;
}

.no-border-bottom {
  border-bottom: none;
}

.no-border {
  border-top: none;
  border-right: none;
  border-left: none;
}

.form-col-top-custom {
  border-top: none;
}
</style>
