import { createHelpers, getField, updateField } from "vuex-map-fields";
import _ from "lodash";
import { ulid } from "ulid";
// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

export const reviewCadastroCliente = Object.freeze({
  correct: false,
  critical: false,
  currentValue: null,
  field: null,
  originalValue: null,
  overwritten: false,
  overwrittenIgnored: false
});

// initial state
const state = {
  reviewcadastrocliente: _.cloneDeep(reviewCadastroCliente)
};

// getters
const getters = {
  reviewcadastrocliente(state) {
    debugger
    return state.reviewcadastrocliente;
  },
  getField,
  getReviewCadastroClienteField(state) {
    return getField(state.reviewcadastrocliente);
  }
};

// mutations
const mutations = {
  updateField,
  updateReviewCadastroClienteField(state, field) {
    updateField(state.reviewcadastrocliente, field);
  }
};

// actions
const actions = {
  newCadastroCliente({ commit, state }) {
    return new Promise(resolve => {
      commit("updateField", { path: "reviewcadastrocliente", value: _.cloneDeep(reviewCadastroCliente) });
      resolve(state.reviewcadastrocliente);
    });
  },
  setCadastroCliente({ commit, state }, reviewcadastrocliente) {
    return new Promise(resolve => {
      commit("updateField", {
        path: "reviewcadastrocliente",
        value: _.merge(_.cloneDeep(reviewCadastroCliente), reviewcadastrocliente)
      });
      resolve(state.reviewcadastrocliente);
    });
  }
};

// EXPORTS
export const { mapFields: mapReviewCadastroClienteFields } = createHelpers({
  getterType: "reviewcadastrocliente/getReviewCadastroClienteField",
  mutationType: "reviewcadastrocliente/updateReviewCadastroClienteField"
});

// EXPORTS
export const { mapFields: mapReviewCadastroCliente } = createHelpers({
  getterType: "reviewcadastrocliente/getField"
});


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
