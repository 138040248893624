import { createHelpers, getField, updateField } from "vuex-map-fields";
import _ from "lodash";
import { ulid } from "ulid";
// Ref:
//   - https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
//   - https://github.com/maoberlehner/vuex-map-fields#vuex-map-fields
//   - https://github.com/robinvdvleuten/vuex-persistedstate

export const reviewRespostaAssinoEmpty = Object.freeze({
  correct: false,
  critical: false,
  currentValue: null,
  field: null,
  originalValue: null,
  overwritten: false,
  overwrittenIgnored: false
});

// initial state
const state = {
  reviewrespostaAssino: _.cloneDeep(reviewRespostaAssinoEmpty)
};

// getters
const getters = {
  reviewrespostaAssino(state) {
    debugger
    return state.reviewrespostaAssino;
  },
  getField,
  getReviewRespostaAssinoField(state) {
    return getField(state.reviewrespostaAssino);
  }
};

// mutations
const mutations = {
  updateField,
  updateReviewRespostaAssinoField(state, field) {
    updateField(state.reviewrespostaAssino, field);
  }
};

// actions
const actions = {
  newRespostaAssino({ commit, state }) {
    return new Promise(resolve => {
      commit("updateField", { path: "reviewrespostaAssino", value: _.cloneDeep(reviewRespostaAssinoEmpty) });
      resolve(state.reviewrespostaAssino);
    });
  },
  setRespostaAssino({ commit, state }, reviewrespostaAssino) {
    return new Promise(resolve => {
      commit("updateField", {
        path: "reviewrespostaAssino",
        value: _.merge(_.cloneDeep(reviewRespostaAssinoEmpty), reviewrespostaAssino)
      });
      resolve(state.reviewrespostaAssino);
    });
  }
};

// EXPORTS
export const { mapFields: mapReviewRespostaAssinoFields } = createHelpers({
  getterType: "reviewrespostaAssino/getReviewRespostaAssinoField",
  mutationType: "reviewrespostaAssino/updateReviewRespostaAssinoField"
});

// EXPORTS
export const { mapFields: mapReviewRespostaAssino } = createHelpers({
  getterType: "reviewrespostaAssino/getField"
});


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
