<template>
  <div>
    <v-dialog max-width="500px" persistent v-model="value">
      <v-card>
        <v-toolbar class="primary darken-1" dark>
          <v-toolbar-title>Detalhes do Treinamento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialogFunc" dark icon>
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-list three-line>
              <template v-for="(item, index) in clientes">
                <v-subheader :key="`${item.cpf}-divider-subheader`" v-if="index === 0" v-text="'Clientes'">
                </v-subheader>

                <v-divider :key="`${item.cpf}-divider`" v-if="index !== 0"></v-divider>

                <v-list-item :key="item.valueReviews[5].originalValue" @click="viewCliente(item, index, dossies)">
                  <v-list-item-avatar>
                    <v-icon>fa-user</v-icon>
                  </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-for="(fields, index) in item.valueReviews" v-if="fields.field === 'cpf'" v-html="fields.originalValue"></v-list-item-title>
                      <v-list-item-subtitle v-for="(fields, index) in item.valueReviews" v-if="fields.field === 'nomeCompleto'" class="text-uppercase" v-html="fields.originalValue"></v-list-item-subtitle>
                    </v-list-item-content>
                  
                </v-list-item>
              </template>
            </v-list>

            <v-list three-line>
              <template>
                <v-subheader v-text="'Avaliação de Risco'"></v-subheader>

                <v-list-item @click="viewAvaliacaoRisco(avaliacaoRisco)">
                  <v-list-item-avatar>
                    <v-icon>fa-balance-scale</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="avaliacaoRisco.cpf"></v-list-item-title>
                    <v-list-item-subtitle class="text-uppercase" v-html="avaliacaoRisco.nomeCompleto">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>

            <v-list three-line>
              <template v-if="respostaAssino[0] !== undefined">
                <v-subheader v-text="'Resposta Assino'"></v-subheader>

                <v-list-item @click="viewRespostaAssino(respostaAssino)">
                  <v-list-item-avatar>
                    <v-icon>fa-balance-scale</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="respostaAssino[0]?.originalValue"></v-list-item-title>
                    <v-list-item-subtitle class="text-uppercase" v-html="respostaAssino[7]?.originalValue">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>

          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <VisualizarClienteDialog :cliente="clienteSelecionado" :dossie="dossieSelecionado" :close="onClose"
      v-model="showCliente"></VisualizarClienteDialog>

    <VisualizarAvaliacaoRiscoDialog :avaliacao-risco="avaliacaoRiscoSelecionado" :close="onClose"
      v-model="showAvaliacaoRisco"></VisualizarAvaliacaoRiscoDialog>

    <VisualizarRespostaAssinoDialog :resposta-assino="respostaAssinoSelecionado" :close="onClose"
      v-model="showRespostaAssino"></VisualizarRespostaAssinoDialog>
  </div>
</template>

<script>
import _ from "lodash";
import VisualizarClienteDialog from "./VisualizarClienteDialog";
import VisualizarAvaliacaoRiscoDialog from "./VisualizarAvaliacaoRiscoDialog";
import VisualizarRespostaAssinoDialog from "./VisualizarRespostaAssinoDialog";
import { clienteEmpty } from "../../store/modules/caixaqui-clientes";
import { avaliacaoRiscoEmpty } from "../../store/modules/caixaqui-avaliacao-risco";
import { respostaAssinoEmpty } from "../../store/modules/review-resposta-assino";
import { cadastroClienteEmpty } from "../../store/modules/review-cadastro-cliente";

export default {
  name: "VisualizarTreinamentoDialog",
  components: {
    VisualizarClienteDialog,
    VisualizarAvaliacaoRiscoDialog,
    VisualizarRespostaAssinoDialog
  },
  props: ["value", "treinamento"],
  data() {
    return {
      visible: false,
      showCliente: false,
      showAvaliacaoRisco: false,
      showRespostaAssino: false,
      clienteSelecionado: _.cloneDeep(cadastroClienteEmpty),
      dossieSelecionado: _.cloneDeep(clienteEmpty),
      avaliacaoRiscoSelecionado: _.cloneDeep(avaliacaoRiscoEmpty),
      respostaAssinoSelecionado: _.cloneDeep(respostaAssinoEmpty)
    };
  },
  computed: {
    clientes() {
      if (this.treinamento.review == undefined) {
        return {};
      } else {
        return this.treinamento.review.clienteReviews;
      }
    },
    dossies() {
      return this.treinamento.dossie.clientes || [];
    },
    avaliacaoRisco() {
      return this.treinamento.avaliacaoRisco || {};
    },
    respostaAssino() {
      if (this.treinamento.review == undefined) {
        return {};
      } else {
        return this.treinamento.review.respostaAssinoReview !== undefined ? this.treinamento.review.respostaAssinoReview.valueReviews : {};
      }
    }
  },
  methods: {
    updateModel: function (value) {
      this.$emit("input", value);
      this.$log.debug(value);
    },
    closeDialogFunc() {
      this.visible = false;
      this.updateModel(this.visible);
      this.$emit("close");
    },
    viewCliente(cliente, index, dossies) {
      this.$log.debug(cliente.valueReviews);
      this.$log.debug(dossies[index]);

      var incomesCNPJ = []
      for (var income in dossies[index].rendasFormais){
        if(dossies[index].rendasFormais[income].cpfCnpj !== ""){
          incomesCNPJ.push(dossies[index].rendasFormais[income].cpfCnpj)
        }
      }

      this.$log.debug(incomesCNPJ)
      this.clienteSelecionado = _.merge(_.cloneDeep(cadastroClienteEmpty), cliente.valueReviews);
      this.dossieSelecionado = _.merge(_.cloneDeep(clienteEmpty), incomesCNPJ);
      this.showCliente = true;
    },
    viewAvaliacaoRisco(avaliacaoRisco) {
      this.$log.debug(avaliacaoRisco);
      this.avaliacaoRiscoSelecionado = _.merge(_.cloneDeep(avaliacaoRiscoEmpty), avaliacaoRisco);
      this.showAvaliacaoRisco = true;
    },
    viewRespostaAssino(respostaAssino) {
      this.respostaAssinoSelecionado = _.merge(_.cloneDeep(respostaAssinoEmpty), respostaAssino);
      this.showRespostaAssino = true;
    },
    onClose() {
      this.clienteSelecionado = _.cloneDeep(cadastroClienteEmpty);
      this.dossieSelecionado = _.cloneDeep(clienteEmpty);
      this.avaliacaoRiscoSelecionado = _.cloneDeep(avaliacaoRiscoEmpty);
      this.respostaAssinoSelecionado = _.cloneDeep(respostaAssinoEmpty);
    }
  }
};
</script>

<style scoped>

</style>
