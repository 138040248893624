<template>
  <v-dialog
    @keydown.esc="closeDialogFunc"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    v-model="value"
  >
    <v-card>
      <v-toolbar class="primary darken-1" dark fixed>
        <v-toolbar-title class="text-uppercase"
          >Resposta Assino - {{ respostaAssino[0]?.originalValue }} - {{ respostaAssino[7]?.originalValue  }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn @click="closeDialogFunc" dark icon>
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container>
        <RespostaAssinoResumo :resposta-assino="respostaAssino"></RespostaAssinoResumo>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import RespostaAssinoResumo from "./RespostaAssinoResumo";

export default {
  name: "VisualizarRespostaAssinoDialog",
  components: { RespostaAssinoResumo },
  props: ["value", "respostaAssino"],
  data() {
    return {
      visible: false
    };
  },
  methods: {
    updateModel: function(value) {
      debugger
      this.$emit("input", value);
    },
    closeDialogFunc() {
      this.visible = false;
      this.updateModel(this.visible);
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
