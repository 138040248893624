var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        persistent: "",
        transition: "dialog-bottom-transition"
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialogFunc.apply(null, arguments)
        }
      },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { staticClass: "primary darken-1", attrs: { dark: "", fixed: "" } },
            [
              _c("v-toolbar-title", { staticClass: "text-uppercase" }, [
                _vm._v(
                  "Resposta Assino - " +
                    _vm._s(_vm.respostaAssino[0]?.originalValue) +
                    " - " +
                    _vm._s(_vm.respostaAssino[7]?.originalValue)
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", icon: "" },
                  on: { click: _vm.closeDialogFunc }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("fa-times")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c("RespostaAssinoResumo", {
                attrs: { "resposta-assino": _vm.respostaAssino }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }