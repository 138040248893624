var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CorrigirTreinamentoView", {
        staticStyle: { "margin-bottom": "36px" },
        attrs: {
          loading: _vm.loading,
          "nome-dossie": _vm.currentTreinamento.dossie.nome,
          "nome-funcionario": _vm.currentTreinamento.createdBy.name,
          value: _vm.reviewClone
        },
        on: { input: _vm.updateReview, reviews: _vm.reviewsUpdate }
      }),
      _c(
        "v-bottom-navigation",
        {
          staticStyle: { "margin-bottom": "36px" },
          attrs: { fixed: "", grow: "" },
          model: {
            value: _vm.activeBtn,
            callback: function($$v) {
              _vm.activeBtn = $$v
            },
            expression: "activeBtn"
          }
        },
        [
          _c(
            "v-btn",
            { attrs: { value: "salvar" }, on: { click: _vm.salvarCorrecao } },
            [
              _c("span", [_vm._v("Salvar Correção")]),
              _c("v-icon", [_vm._v("fa-save")])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { value: "finalizar" },
              on: { click: _vm.finalizarCorrecao }
            },
            [
              _c("span", [_vm._v("Finalizar Correção")]),
              _c("v-icon", [_vm._v("fa-stop")])
            ],
            1
          ),
          _c(
            "download-excel",
            {
              staticClass:
                "v-btn v-btn--contained theme--light v-size--default",
              attrs: { data: _vm.excelData, fields: _vm.excelFields }
            },
            [
              _c(
                "span",
                { staticClass: "v-btn__content" },
                [
                  _c("span", [_vm._v("Download")]),
                  _c("v-icon", [_vm._v("fa-download")])
                ],
                1
              )
            ]
          ),
          _c(
            "v-btn",
            { attrs: { value: "cancelar" }, on: { click: _vm.cancelar } },
            [
              _c("span", [_vm._v("Cancelar")]),
              _c("v-icon", [_vm._v("fa-undo")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }