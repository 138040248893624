var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        "max-width": _vm.maxWidth,
        "min-width": _vm.minWidth,
        "offset-y": "",
        transition: "scale-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##/##/####",
                        expression: "'##/##/####'"
                      }
                    ],
                    attrs: {
                      label: _vm.label,
                      autocomplete: "off",
                      hint: "DD/MM/YYYY",
                      id: "date-input",
                      "persistent-hint": "",
                      "prepend-icon": "fa-calendar-alt"
                    },
                    on: {
                      blur: function($event) {
                        _vm.updateValue(_vm.parseDate(_vm.dateFormatted))
                      }
                    },
                    model: {
                      value: _vm.dateFormatted,
                      callback: function($$v) {
                        _vm.dateFormatted = $$v
                      },
                      expression: "dateFormatted"
                    }
                  },
                  on
                )
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _c("v-date-picker", {
        attrs: { max: _vm.max, min: _vm.min, value: _vm.value, "no-title": "" },
        on: { input: _vm.datePickerInputEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }