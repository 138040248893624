<template>
  <v-container>
    <div class="d-flex justify-center">
      <v-form class="form-size">
        <v-text-field
          label="Nome do Dossiê"
          disabled
          outlined
          dense
          v-model="dossie.nome"
          class="d-flex"
        ></v-text-field>

        <v-text-field
          v-for="item in inputClientes"
          :key="item.index"
          :label="`CPF Cadastro ${item.index + 1} ${item.nome ? '- ' + item.nome.toUpperCase() : ''}`"
          v-model="item.cpf"
          readonly
          outlined
          dense
          class="input-size"
        >
          <template v-slot:append-outer>
            <v-icon small class="mr-3" @click="editCliente(item.cliente)">
              fa-edit
            </v-icon>
            <v-icon small @click="deleteCliente(item.cliente)">
              fa-trash
            </v-icon>
          </template>
        </v-text-field>

        <v-text-field
          :label="avaliacaoRiscoLabel"
          class="input-size"
          dense
          outlined
          readonly
          v-model="avaliacaoRiscoCpf"
        >
          <template v-slot:append-outer>
            <v-icon small class="mr-3" @click="editAvaliacaoRisco">
              fa-edit
            </v-icon>
            <v-icon small @click="deleteAvaliacaoRisco">
              fa-trash
            </v-icon>
          </template>
        </v-text-field>

        <v-text-field
          :label="respostaAssinoLabel"
          class="input-size"
          dense
          outlined
          readonly
          v-model="respostaAssinoCpf"
        >
          <template v-slot:append-outer>
            <v-icon small class="mr-3" @click="editRespostaAssino">
              fa-edit
            </v-icon>
            <v-icon small @click="deleteRespostaAssino">
              fa-trash
            </v-icon>
          </template>
        </v-text-field>

        <v-btn @click="concluir" class="mr-4">Concluir</v-btn>
        <v-btn @click="salvar" class="mr-4">Salvar</v-btn>
        <v-btn @click="cancelar" class="mr-4">Cancelar</v-btn>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapCurrentTreinamentoFields } from "@/store/modules/treinamento";
import { mapActions } from "vuex";
import ROUTERS from "../../routes/router-constants";

export default {
  name: "TreinamentoItem",
  data: () => ({}),
  created() {
    this.loadIfNeeded();
  },
  computed: {
    ...mapCurrentTreinamentoFields(["id", "dossie", "status", "clientes", "avaliacaoRisco", "respostaAssino"]),
    inputClientes() {
      const result = [];
      for (let i of _.range(3)) {
        if (this.clientes && this.clientes[i]) {
          const cliente = this.clientes[i];
          result.push({
            index: i,
            cpf: cliente.cpf,
            nome: cliente.nomeCompleto,
            cliente: cliente
          });
        } else {
          result.push({ index: i, cpf: null, nome: null, cliente: null });
        }
      }
      return result;
    },
    avaliacaoRiscoCpf() {
      return _.has(this.avaliacaoRisco, "cpf") ? this.avaliacaoRisco.cpf : null;
    },
    avaliacaoRiscoLabel() {
      return `Avaliação de Risco ${
        _.has(this.avaliacaoRisco, "nomeCompleto") ? "- " + this.avaliacaoRisco.nomeCompleto.toUpperCase() : ""
      }`;
    },
    respostaAssinoCpf() {
      return _.has(this.respostaAssino, "cpfProponente1") ? this.respostaAssino.cpfProponente1 : null;
    },
    respostaAssinoLabel() {
      return `Resposta Assino ${
        _.has(this.respostaAssino, "nomeProponente1") ? "- " + this.respostaAssino.nomeProponente1.toUpperCase() : ""
      }`;
    }
  },
  methods: {
    ...mapActions("treinamento", [
      "resetCurrentTreinamento",
      "saveCurrentTreinamento",
      "loadTreinamento",
      "removeClient"
    ]),
    ...mapActions("controller", [
      "treinamentoNovoCliente",
      "treinamentoEditCliente",
      "treinamentoNovaAvaliacaoRisco",
      "treinamentoNovaRespostaAssino",
      "treinamentoEditAvaliacaoRisco",
      "treinamentoEditRespostaAssino",
      "concluirTreinamento"
    ]),
    loadIfNeeded() {
      this.$log.debug("this.$route.params.id", this.$route.params.id);
      if (this.$route.params.id && this.id !== this.$route.params.id && this.$route.params.id !== "new") {
        this.loadTreinamento(this.$route.params.id).then(value => {
          this.$store.commit("treinamento/currentTreinamento", value);
        });
      }
    },
    salvar() {
      this.saveCurrentTreinamento().then(() => {
        this.$alert.notifySuccess("Salvo com sucesso!");
      });
    },
    cancelar() {
      this.resetCurrentTreinamento();
      this.$router.push({ name: ROUTERS.perfil.name });
    },
    concluir() {
      this.concluirTreinamento().then(() => {
        this.$alert.notifySuccess("Concluído com sucesso!");
        this.$router.push({ name: ROUTERS.perfil.name });
      });
    },
    getRoute: function() {
      return { name: ROUTERS.treinamento.name, params: { id: this.$route.params.id } };
    },
    editCliente(cliente) {
      this.$log.debug(cliente);
      const data = {
        sourceRoute: { name: this.$route.name, params: this.$route.params },
        router: this.$router
      };

      if (cliente) {
        this.treinamentoEditCliente({ ...data, cliente: cliente });
      } else {
        this.treinamentoNovoCliente(data);
      }
    },
    deleteCliente(cliente) {
      this.$log.debug(cliente);
      if (cliente) {
        this.removeClient(cliente);
      }
    },
    editAvaliacaoRisco() {
      const avaliacaoRisco = this.avaliacaoRisco;
      this.$log.debug(avaliacaoRisco);

      const data = {
        sourceRoute: { name: this.$route.name, params: this.$route.params },
        router: this.$router
      };

      if (avaliacaoRisco) {
        this.treinamentoEditAvaliacaoRisco({ ...data, avaliacaoRisco: avaliacaoRisco });
      } else {
        this.treinamentoNovaAvaliacaoRisco(data);
      }
    },
    deleteAvaliacaoRisco() {
      this.$log.debug(this.avaliacaoRisco);
      this.avaliacaoRisco = { cpf: null };
    },
    editRespostaAssino() {
      const respostaAssino = this.respostaAssino;
      this.$log.debug(respostaAssino);

      const data = {
        sourceRoute: { name: this.$route.name, params: this.$route.params },
        router: this.$router
      };

      if (respostaAssino) {
        this.treinamentoEditRespostaAssino({ ...data, respostaAssino: respostaAssino });
      } else {
        this.treinamentoNovaRespostaAssino(data);
      }
    },
    deleteRespostaAssino() {
      this.$log.debug(this.respostaAssino);
      this.respostaAssino = { cpf: null };
    }
  }
};
</script>

<style scoped>
.form-size {
  width: 800px;
}

.input-size {
  width: 500px;
}
</style>
