<template>
  <div>
    <CorrigirTreinamentoView
      :loading="loading"
      :nome-dossie="currentTreinamento.dossie.nome"
      :nome-funcionario="currentTreinamento.createdBy.name"
      :value="reviewClone"
      @input="updateReview"
      @reviews="reviewsUpdate"
      style="margin-bottom: 36px"
    ></CorrigirTreinamentoView>
    <v-bottom-navigation fixed grow style="margin-bottom: 36px" v-model="activeBtn">
      <v-btn @click="salvarCorrecao" value="salvar">
        <span>Salvar Correção</span>
        <v-icon>fa-save</v-icon>
      </v-btn>
      <v-btn @click="finalizarCorrecao" value="finalizar">
        <span>Finalizar Correção</span>
        <v-icon>fa-stop</v-icon>
      </v-btn>

      <download-excel
        :data="excelData"
        :fields="excelFields"
        class="v-btn v-btn--contained theme--light v-size--default"
      >
        <span class="v-btn__content">
          <span>Download</span>
          <v-icon>fa-download</v-icon>
        </span>
      </download-excel>

      <v-btn @click="cancelar" value="cancelar">
        <span>Cancelar</span>
        <v-icon>fa-undo</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import { mapCurrentTreinamentoFields, mapTreinamentoFields } from "../../store/modules/treinamento";
import CorrigirTreinamentoView from "../../components/caixaqui/CorrigirTreinamentoView";
import ROUTERS from "../../routes/router-constants";
import JsonExcel from "vue-json-excel";

export default {
  name: "CorrecaoItem",
  components: { CorrigirTreinamentoView, downloadExcel: JsonExcel },
  data() {
    return {
      activeBtn: "",
      excelData: []
    };
  },
  computed: {
    ...mapTreinamentoFields(["currentTreinamento", "loading"]),
    ...mapCurrentTreinamentoFields(["review"]),
    reviewClone() {
      return _.cloneDeep(this.review);
    },
    excelFields() {
      return {
        Tipo: "tipo",
        Campo: "value.field",
        "Valor Dossie": {
          field: "value.originalValue",
          callback: value => {
            return this.valueText(value);
          }
        },
        "Valor Treinamento": {
          field: "value.currentValue",
          callback: value => {
            return this.valueText(value);
          }
        },
        Ignorado: "value.ignoredComputed",
        Correto: "value.correctComputed",
        Sobrescrito: "value.overwritten"
      };
    }
  },
  created() {
    this.loadIfNeeded();
  },
  methods: {
    ...mapActions("treinamento", ["loadTreinamento", "saveReview"]),
    ...mapActions("controller", []),
    loadIfNeeded() {
      this.$log.debug("this.$route.params.id", this.$route.params.id);

      if (_.has(this.currentTreinamento, "id") && !_.isNil(this.currentTreinamento.id)) {
        this.$log.debug("Using currentTreinamento", this.$route.params.id);
        this.$route.params.id = this.currentTreinamento.id;
      } else if (this.$route.params.id) {
        this.$log.debug("Loading treinamento", this.$route.params.id);
        this.loadTreinamento(this.$route.params.id).then(value => {
          this.$store.commit("treinamento/currentTreinamento", value);
        });
      }
    },
    cancelar() {
      this.$alert.confirmation("Deseja cancelar a correção?", "Todas as alterações serão perdidas").then(result => {
        if (result.value) {
          this.$router.push({ name: ROUTERS.correcao.name });
        }
      });
    },
    updateReview(review) {
      this.$log.debug(review);
      this.review = review;
    },
    reviewsUpdate(reviews) {
      this.excelData = reviews;
    },
    valueText(value) {
      return _.isNil(value) || value === "" ? "[vazio]" : value.toUpperCase();
    },
    salvarCorrecao() {
      this.$log.debug(this.currentTreinamento);
      this.saveReview({
        treinamentoId: this.currentTreinamento.id,
        review: this.review,
        finish: false
      }).then(value => {
        this.review = value;
      });
    },
    finalizarCorrecao() {
      this.$log.debug(this.currentTreinamento);

      this.$alert
        .confirmation("Deseja finalizar a correção?", "O status do treinamento mudará para Corrigido.")
        .then(result => {
          if (result.value) {
            this.saveReview({
              treinamentoId: this.currentTreinamento.id,
              review: this.review,
              finish: true
            }).then(value => {
              this.review = value;
              this.$router.push({ name: ROUTERS.correcao.name });
            });
          }
        });
    }
  }
};
</script>

<style scoped src="../../components/caixaqui/caixaqui-style.css"></style>
<style scoped></style>
