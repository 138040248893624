<template>
  <v-dialog
    @keydown.esc="closeDialogFunc"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
    v-model="value"
  >
    <v-card>
      <v-toolbar class="primary darken-1" dark fixed>
        <v-toolbar-title class="text-uppercase"
          >Avaliação Risco - {{ avaliacaoRisco.cpf }} - {{ avaliacaoRisco.nomeCompleto }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn @click="closeDialogFunc" dark icon>
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container>
        <AvaliacaoRiscoResumo :avaliacao-risco="avaliacaoRisco"></AvaliacaoRiscoResumo>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import AvaliacaoRiscoResumo from "./AvaliacaoRiscoResumo";

export default {
  name: "VisualizarAvaliacaoRiscoDialog",
  components: { AvaliacaoRiscoResumo },
  props: ["value", "avaliacaoRisco"],
  data() {
    return {
      visible: false
    };
  },
  methods: {
    updateModel: function(value) {
      this.$emit("input", value);
    },
    closeDialogFunc() {
      this.visible = false;
      this.updateModel(this.visible);
      this.$emit("close");
    }
  }
};
</script>

<style scoped></style>
