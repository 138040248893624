<template>
  <div>
    <div class="d-flex justify-center mt-3">
      <v-card class="pa-1" outlined tile>
        <v-data-table :footer-props="footer" :headers="headers" :items="reviews" :loading="loading" :search="search"
          :options.sync="options" class="data-table elevation-0" dense item-key="value.id" multi-sort>
          <template v-slot:top>
            <v-toolbar class="mb-5" color="white" flat>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-center">
                  <span class="header-label subtitle-2 font-weight-medium text-right mr-2">funcionário:</span>
                  <span class="subtitle-1 text-uppercase">{{ nomeFuncionario }}</span>
                </div>
                <div class="d-flex flex-row align-center">
                  <span class="header-label subtitle-2 font-weight-medium text-right mr-2">dossiê:</span>
                  <span class="subtitle-2 font-weight-light">{{ nomeDossie }}</span>
                </div>
                <div class="d-flex flex-row align-center">
                  <span class="header-label subtitle-2 font-weight-medium text-right mr-2">nota:</span>
                  <span class="subtitle-2 font-weight-light">{{ grade }}% ({{ gradeRaw }}%)</span>
                </div>
              </div>

              <v-spacer></v-spacer>

              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-center">
                  <v-select :items="tipos" class="mx-3" clearable hide-details label="Tipo" single-line
                    v-model="searchTipo" @change="onFilterChangeEvent"></v-select>

                  <v-select :items="telas" class="mx-3" clearable hide-details label="Tela" single-line
                    v-model="searchTela" @change="onFilterChangeEvent"></v-select>

                  <v-select :items="statuses" clearable hide-details label="Status" single-line v-model="searchStatus"
                    @change="onFilterChangeEvent"></v-select>
                </div>

                <div class="d-flex flex-row align-center">
                  <v-text-field append-icon="fa-search" class="mt-2 ml-3" clearable hide-details label="Pesquisar"
                    single-line v-model="search" @change="onFilterChangeEvent"></v-text-field>
                </div>
              </div>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr :class="rowClass(item)">
              <td>
                <span class="data-table-font data-table-tipo">{{ item.tipo
                }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-field">{{ item.tela
                }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-field">{{
                    item.value.field
                }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-valor-dossie">{{
                    valueText(item.value.originalValue)
                }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-valor-treinamento">{{
                    valueText(item.value.currentValue)
                }}</span>
              </td>
              <td>
                <v-tooltip bottom v-if="ignored(item.value)">
                  <template v-slot:activator="{ on }">
                    <v-icon color="gray darken-2" v-on="on">fa-minus-circle</v-icon>
                  </template>
                  <span class="ma-3">{{ ignoredTooltipText }}</span>
                </v-tooltip>

                <v-tooltip bottom v-else-if="correct(item.value)">
                  <template v-slot:activator="{ on }">
                    <v-icon color="green darken-2" dark v-on="on">fa-check</v-icon>
                  </template>
                  <span class="ma-3">{{ correctTooltipText }}</span>
                </v-tooltip>

                <v-tooltip bottom v-else-if="!correct(item.value)">
                  <template v-slot:activator="{ on }">
                    <v-icon color="red darken-2" dark v-on="on">fa-times</v-icon>
                  </template>
                  <span class="ma-3">{{ incorrectTooltipText }}</span>
                </v-tooltip>
              </td>
              <td>
                <v-simple-checkbox @input="updateModel" v-model="item.value.overwritten"></v-simple-checkbox>
              </td>
              <td>
                <v-simple-checkbox @input="updateModel" v-model="item.value.overwrittenIgnored"></v-simple-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div class="d-flex justify-center mt-3">
      <v-card class="pa-1" outlined tile width="1267">
        <v-data-table :footer-props="footerRespostaAssino" :headers="headersRespostaAssino" :items="reviewsAssino"
          :loading="loading" :search="search" :options.sync="optionsRespostaAssino" class="data-table elevation-0" dense
          item-key="value.id" multi-sort>
          <template v-slot:top>
            <v-toolbar class="mb-5" color="white" flat>

              <v-spacer>
                <h1>Resposta Assino</h1>
              </v-spacer>

              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-center">
                </div>
              </div>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr :class="rowClassRespostaAssino(item)">
              <td>
                <span class="data-table-font data-table-field">{{ item.tela }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-field">{{ item.value.field }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-valor-dossie">{{
                    valueText(item.value.originalValue)
                }}</span>
              </td>
              <td>
                <span class="data-table-font data-table-valor-treinamento-resposta-assino">{{
                    valueText(item.value.currentValue)
                }}</span>
              </td>
              <td>
                <v-tooltip bottom v-if="ignored(item.value)">
                  <template v-slot:activator="{ on }">
                    <v-icon color="gray darken-2" v-on="on">fa-minus-circle</v-icon>
                  </template>
                  <span class="ma-3">{{ ignoredTooltipText }}</span>
                </v-tooltip>

                <v-tooltip bottom v-else-if="correctRespostaAssino(item.value)">
                  <template v-slot:activator="{ on }">
                    <v-icon color="green darken-2" dark v-on="on">fa-check</v-icon>
                  </template>
                  <span class="ma-3">{{ correctTooltipText }}</span>
                </v-tooltip>
              </td>
              <td>
                <v-simple-checkbox @input="updateModel" v-model="item.value.overwritten"></v-simple-checkbox>
              </td>
              <td>
                <v-simple-checkbox @input="updateModel" v-model="item.value.overwrittenIgnored"></v-simple-checkbox>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<br/>
<br/>


<script>
import _ from "lodash";
import { Ripple } from "vuetify/lib/directives";
import { getTelaCliente } from "../../api/model/telaCliente";
import { getTelaAvaliacaoRisco } from "../../api/model/telaAvaliacaoRisco";
import { getTelaRespostaAssino } from "../../api/model/telaRespostaAssino";

export default {
  name: "CorrigirTreinamentoView",
  model: {
    event: "reviews",
    event: "reviewsAssino"
  },
  props: ["value", "loading", "nomeFuncionario", "nomeDossie"],
  directives: {
    Ripple
  },
  data() {
    return {
      searchTipo: null,
      searchTela: null,
      searchStatus: null,
      search: "",
      options: {
        page: 1
      },
      optionsRespostaAssino: {
        page: 1
      },
      footer: {
        itemsPerPageOptions: [10, 25, 50, 75, 100, 125, 150, -1],
        showCurrentPage: true,
        showFirstLastPage: true
      },
      footerRespostaAssino: {
        itemsPerPageOptions: [10, 25, 50, 75, 100, 125, 150, -1],
        showCurrentPage: true,
        showFirstLastPage: true
      },
      ignoredTooltipText: "Ignorado no cálculo da nota",
      correctTooltipText: "Resposta correta",
      incorrectTooltipText: "Resposta incorreta",
      statuses: ["Correto", "Incorreto", "Ignorado"]
    };
  },
  computed: {
    headers() {
      return [
        { text: "Tipo", align: "left", sortable: true, value: "tipo", filter: this.tiposFilter },
        { text: "Tela", align: "left", sortable: true, value: "tela", filter: this.telaFilter },
        { text: "Campo", align: "left", sortable: true, value: "value.field" },
        {
          text: "Valor Dossiê",
          align: "left",
          sortable: true,
          value: "value.originalValue"
        },
        {
          text: "Valor Treinamento",
          align: "left",
          sortable: true,
          value: "value.currentValue"
        },
        { text: "Correto", align: "left", sortable: true, value: "value", filter: this.statusFilter },
        { text: "Sobrescrito", align: "left", sortable: true, value: "value.overwritten" },
        { text: "Ignorar", align: "left", sortable: true, value: "value.overwrittenIgnored" }
      ];
    },
    headersRespostaAssino() {
      return [
        { text: "Tela", align: "left", sortable: true, value: "tela", filter: this.telaFilter },
        { text: "Campo", align: "left", sortable: true, value: "value.field" },
        {
          text: "Valor Dossiê",
          align: "left",
          sortable: true,
          value: "value.originalValue"
        },
        {
          text: "Valor Treinamento",
          align: "left",
          sortable: true,
          value: "value.currentValue"
        },
        { text: "Correto", align: "left", sortable: true, value: "value", filter: this.statusFilter },
        { text: "Sobrescrito", align: "left", sortable: true, value: "value.overwritten" },
        { text: "Ignorar", align: "left", sortable: true, value: "value.overwrittenIgnored" }
      ];
    },
    grade() {
      return _.has(this.value, "grade") ? _.round(this.value.grade * 100) : 0;
    },
    gradeRaw() {
      return _.has(this.value, "grade") ? _.round(this.value.grade * 100, 2) : 0;
    },
    tipos() {
      const tiposList = [];
      if (!_.isNil(this.value)) {
        const review = this.value;

        if (_.has(review, "clienteReviews") && !_.isNil(review.clienteReviews)) {
          const clienteReviews = review.clienteReviews.filter(function (el) {
            return el != null;
          });

          _.forEach(clienteReviews, clienteReview => {
            const tipo = `Cliente - ${clienteReview.id}`;
            tiposList.push(tipo);
          });
        }

        if (_.has(review, "avaliacaoRiscoReview") && !_.isNil(review.avaliacaoRiscoReview)) {
          const arReview = review.avaliacaoRiscoReview;
          const tipo = `Avaliação Risco - ${arReview.id}`;
          tiposList.push(tipo);
        }
      }

      return tiposList;
    },
    tiposAssino() {
      const tiposListAssino = [];
      if (!_.isNil(this.value)) {
        const review = this.value;

        if (_.has(review, "RespostaAssinoReview") && !_.isNil(review.respostaAssinoReview)) {
          const arReview = review.respostaAssinoReview;
          const tipo = `Resposta Assino - ${arReview.id}`;
          tiposListAssino.push(tipo);
        }

        return tiposListAssino;
      }
    },
    reviews() {
      const reviewsList = [];
      if (!_.isNil(this.value)) {
        const review = this.value;

        if (_.has(review, "clienteReviews") && !_.isNil(review.clienteReviews)) {
          const clienteReviews = review.clienteReviews.filter(function (el) {
            return el != null;
          });

          _.forEach(clienteReviews, (clienteReview, cliIndex) => {
            const tipo = `Cliente - ${clienteReview.id}`;

            _.forEach(clienteReview.valueReviews, value => {
              const telaCliente = this.getTelaCliente(value.field);
              reviewsList.push({
                order: cliIndex,
                tipo: tipo,
                tela: telaCliente.nome,
                telaOrder: telaCliente.telaOrder,
                fieldOrder: telaCliente.fieldOrder,
                value: value
              });
            });
          });
        }

        if (_.has(review, "avaliacaoRiscoReview") && !_.isNil(review.avaliacaoRiscoReview)) {
          const arReview = review.avaliacaoRiscoReview;
          const tipo = `Avaliação Risco - ${arReview.id}`;

          _.forEach(arReview.valueReviews, value => {
            const telaAvaliacaoRisco = this.getTelaAvaliacaoRisco(value.field);
            if (telaAvaliacaoRisco.nome != "Autofinanciamento") {
              reviewsList.push({
                order: 99,
                tipo: tipo,
                tela: telaAvaliacaoRisco.nome,
                telaOrder: telaAvaliacaoRisco.telaOrder,
                fieldOrder: telaAvaliacaoRisco.fieldOrder,
                value: value
              });
            }
          });
        }
      }
      this.$emit("reviews", reviewsList);

      return _.sortBy(reviewsList, ["order", "telaOrder", "fieldOrder", "value.field"]);
    },
    reviewsAssino() {
      const reviewsAssinoList = [];
      if (!_.isNil(this.value)) {
        const review = this.value;

        if (_.has(review, "respostaAssinoReview") && !_.isNil(review.respostaAssinoReview)) {
          const arReview = review.respostaAssinoReview;

          _.forEach(arReview.valueReviews, value => {
            const telaRespostaAssino = this.getTelaRespostaAssino(value.field);
            reviewsAssinoList.push({
              order: 99,
              tela: telaRespostaAssino.nome,
              telaOrder: telaRespostaAssino.telaOrder,
              fieldOrder: telaRespostaAssino.fieldOrder,
              value: value
            });
          });
        }
      }
      this.$emit("reviews", reviewsAssinoList);

      return _.sortBy(reviewsAssinoList, ["order", "telaOrder", "fieldOrder", "value.field"]);
    },
    telas() {
      function getTipoCode(tipo) {
        if (_.startsWith(tipo, "Avaliação Risco")) {
          return "AR";
        }
        return "CLI";
      }

      return _.chain(this.reviews)
        .map(r => {
          return { text: `[${getTipoCode(r.tipo)}] ${r.tela}`, value: r.tela };
        })
        .uniq()
        .value();
    },
    telasAssino() {
      function getTipoCodeAssino(tipo) {
        if (_.startsWith(tipo, "Resposta Assino")) {
          return "AR";
        }
        return "CLI";
      }

      return _.chain(this.reviews)
        .map(r => {
          return { text: `[${getTipoCodeAssino(r.tipo)}] ${r.tela}`, value: r.tela };
        })
        .uniq()
        .value();
    }
  },
  methods: {
    updateModel: function () {
      this.$log.debug(this.value);
      this.$emit("input", this.value);
    },
    valueText(value) {
      return _.isNil(value) || value === "" ? "[vazio]" : value.toUpperCase();
    },
    correct(value) {
      return value.overwritten ? !value.correct : value.correct;
    },
    correctRespostaAssino(value) {
      return value.overwritten ? !value.correct : value.correct;
    },
    ignored(value) {
      return value.overwrittenIgnored || ((!value.overwritten || !value.ignored) && value.ignored);
    },
    rowClass(item) {
      if (this.ignored(item.value)) {
        return "data-table-row-ignored";
      }
      return this.correct(item.value) ? "data-table-row-correct" : "data-table-row-incorrect";
    },
    rowClassRespostaAssino(item) {
      if (this.ignored(item.value)) {
        return "data-table-row-ignored";
      }
      return this.correctRespostaAssino(item.value) ? "data-table-row-correct" : "data-table-row-incorrect";
    },
    tiposFilter(value) {
      if (!this.searchTipo) {
        return true;
      }

      return value === this.searchTipo;
    },
    telaFilter(value) {
      if (!this.searchTela) {
        return true;
      }

      return value === this.searchTela;
    },
    statusFilter(value) {
      if (!this.searchStatus) {
        return true;
      }

      if (this.searchStatus === "Correto") {
        return this.correct(value) && !this.ignored(value);
      }

      if (this.searchStatus === "Incorreto") {
        return !this.correct(value) && !this.ignored(value);
      }

      if (this.searchStatus === "Ignorado") {
        return this.ignored(value);
      }

      return true;
    },
    getTelaCliente(field) {
      return getTelaCliente(field);
    },
    getTelaAvaliacaoRisco(field) {
      return getTelaAvaliacaoRisco(field);
    },
    getTelaRespostaAssino(field) {
      return getTelaRespostaAssino(field);
    },
    onFilterChangeEvent(event) {
      this.$log.debug(event);
      this.$nextTick().then(() => {
        this.options.page = 1;
      });
    },
    onFilterChangeEventRespostaAssino(event) {
      this.$log.debug(event);
      this.$nextTick().then(() => {
        this.optionsRespostaAssino.page = 1;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/main";

.header-label {
  width: 80px;
  color: #757575;
}

.data-table-font {
  font-size: 13px;
}

.data-table-row-ignored {
  background-color: #e6e6e6;
}

.data-table-row-incorrect {
  background-color: #ffcabf;
}

.data-table-tipo {
  @extend .text-no-wrap;
}

.data-table-field {
  @extend .font-weight-medium;
  color: #757575;
}
</style>
