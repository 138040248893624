var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main", attrs: { id: "resumo" } },
    [
      _c("v-container", { staticClass: "d-flex justify-center" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-center" },
          [
            _c(
              "v-form",
              { ref: "form", staticClass: "form-size" },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "6", md: "6" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Nome do Proponente 1")]
                              ),
                              _vm.respostaAssino[7].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[7]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[7].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[7]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[7]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[7].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[7].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[7].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6", md: "6" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("CPF do Proponente 1")]
                              ),
                              _vm.respostaAssino[0].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[0]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[0].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[0]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[0]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[0].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[0].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[0].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6", md: "6" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Nome do Proponente 2")]
                              ),
                              _vm.respostaAssino[8].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[8]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[8].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[8]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[8]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[8].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[8].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[8].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "6", md: "6" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("CPF do Proponente 2")]
                              ),
                              _vm.respostaAssino[1].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[1]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[1].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[1]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[1]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[1].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[1].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[1].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Situação da análise")]
                              ),
                              _vm.respostaAssino[15].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[15]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[15].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[15]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[15]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[15].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[15].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[15]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Valor do imóvel")]
                              ),
                              _vm.respostaAssino[18].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[18]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[18].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[18]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[18]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[18].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[18].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[18]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Valor do financiamento")]
                              ),
                              _vm.respostaAssino[17].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[17]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[17].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[17]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[17]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[17].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[17].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[17]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Subsídio")]
                              ),
                              _vm.respostaAssino[16].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[16]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[16].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[16]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[16]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[16].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[16].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[16]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "2", md: "2" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Possui direito a usar FGTS?")]
                              ),
                              _vm.respostaAssino[11].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[11]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[11].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[11]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[11]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[11].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[11].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[11]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "2", md: "2" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("FGTS")]
                              ),
                              _vm.respostaAssino[5].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[5]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[5].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[5]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[5]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[5].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[5].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[5].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Valor total da proposta")]
                              ),
                              _vm.respostaAssino[21].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[21]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[21].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[21]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[21]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[21].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[21].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[21]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "2", md: "2" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Prazo (meses)")]
                              ),
                              _vm.respostaAssino[12].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[12]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[12].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[12]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[12]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[12].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[12].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[12]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Valor da taxa de juros")]
                              ),
                              _vm.respostaAssino[20].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[20]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[20].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[20]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[20]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[20].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[20].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[20]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Prestação aprovada")]
                              ),
                              _vm.respostaAssino[19].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[19]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[19].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[19]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[19]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[19].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[19].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[19]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Renda bruta familiar")]
                              ),
                              _vm.respostaAssino[13].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[13]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[13].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[13]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[13]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[13].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[13].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[13]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Possui direito ao redutor na taxa?")]
                              ),
                              _vm.respostaAssino[10].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[10]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[10].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[10]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[10]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[10].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[10].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[10]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Enquadramento")]
                              ),
                              _vm.respostaAssino[4].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[4]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[4].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[4]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[4]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[4].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[4].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[4].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Sistema de amortização")]
                              ),
                              _vm.respostaAssino[14].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[14]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[14].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[14]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[14]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[14].originalValue ===
                                      ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[14].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[14]
                                                .currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Indexador")]
                              ),
                              _vm.respostaAssino[6].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[6]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[6].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[6]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[6]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[6].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[6].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[6].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Data da avaliação")]
                              ),
                              _vm.respostaAssino[2].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[2]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[2].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[2]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[2]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[2].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[2].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[2].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "3", md: "3" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Data de vencimento")]
                              ),
                              _vm.respostaAssino[3].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[3]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[3].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[3]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[3]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[3].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[3].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[3].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "12" } },
                      [
                        _c(
                          "v-card",
                          { staticClass: "mx-auto" },
                          [
                            _c("v-card-text", [
                              _c(
                                "h4",
                                { staticClass: "dark--text text--lighten-1" },
                                [_vm._v("Observações ou pendências")]
                              ),
                              _vm.respostaAssino[9].correct === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[9]
                                                .originalValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.respostaAssino[9].overwritten === false
                                ? _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "red--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[9]
                                                  .currentValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                    👇\n                    "
                                      ),
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "green--text text--lighten-1",
                                          staticStyle: {
                                            "text-align": "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.respostaAssino[9]
                                                  .originalValue
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm.respostaAssino[9].originalValue === ""
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Não existe informaçãoes para comparação ⚠\n                  "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              _vm.respostaAssino[9].overwritten === true
                                ? _c("div", [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "primary--text text--lighten-1",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.respostaAssino[9].currentValue
                                            ) +
                                            "✅\n                  "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }