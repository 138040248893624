<template>
  <v-container class="d-flex">
    <div class="mx-auto">
      <div class="d-flex justify-center mt-3">
        <v-card class="pa-1" outlined tile>
          <v-data-table
            :footer-props="footer"
            :headers="headers"
            :items="treinamentos"
            :loading="loading"
            :search="search"
            class="data-table elevation-0"
            item-key="id"
          >
            <template v-slot:top>
              <v-toolbar class="mb-3" color="white" flat>
                <v-toolbar-title>CORREÇÃO</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  append-icon="fa-search"
                  hide-details
                  label="Pesquisar"
                  single-line
                  v-model="search"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-radio-group @change="statusFilter" class="mt-11" v-model="selectedStatus">
                  <v-radio :key="item.value" :label="item.text" :value="item.value" v-for="item in statuses"></v-radio>
                </v-radio-group>
              </v-toolbar>
            </template>

            <template v-slot:item.dossie.nome="{ item }">
              <span>{{ item.dossie.nome }}</span>
              <v-tooltip bottom class="ml-3" v-if="isCorrigido(item)">
                <template v-slot:activator="{ on }">
                  <v-icon color="green darken-2" dark v-on="on">fa-check</v-icon>
                </template>
                <span>{{ item.status }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.completedAt="{ item }">
              <span class="table-date-info">{{ formatDate(item.completedAt) }}</span>
            </template>

            <template v-slot:item.nota="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-progress-circular
                    :rotate="-90"
                    :value="grade(item)"
                    color="primary"
                    v-if="showGrade(item)"
                    v-on="on"
                  >
                    {{ grade(item) }}
                  </v-progress-circular>
                </template>
                <span>{{ gradeRaw(item) }}%</span>
              </v-tooltip>
            </template>

            <template v-slot:item.action="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon @click="corrigir(item)" medium v-on="on">fa-edit</v-icon>
                </template>
                <span>Corrigir</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import { mapTreinamentoFields } from "@/store/modules/treinamento";
import ROUTERS from "../../routes/router-constants";

export default {
  name: "CorrecaoList",
  components: {},
  data() {
    return {
      search: "",
      selectedStatus: "CONCLUIDO",
      statuses: [
        { text: "Pendente", value: "CONCLUIDO" },
        { text: "Corrigido", value: "CORRIGIDO" }
      ],
      nomeDossie: null,
      footer: {
        itemsPerPageOptions: [10, 25, 50],
        showCurrentPage: true,
        showFirstLastPage: true
      },
      headers: [
        { text: "Funcionário", align: "left", sortable: true, value: "createdBy.name" },
        { text: "Dossiê", align: "left", sortable: true, value: "dossie.nome" },
        { text: "Concluído em", align: "left", sortable: true, value: "completedAt" },
        { text: "Nota", align: "left", sortable: true, value: "nota" },
        { text: "Ações", align: "left", sortable: false, value: "action", width: "70px" }
      ]
    };
  },
  computed: {
    ...mapTreinamentoFields(["loading", "treinamentos"]),
    isCorrigido: () => item => {
      return item.status === "CORRIGIDO";
    },
    isPendente: () => item => {
      return item.status === "PENDENTE";
    },
    showGrade: () => item => {
      return _.has(item, "review.grade");
    },
    grade: () => item => {
      return _.has(item, "review.grade") ? _.round(item.review.grade) : 0;
    },
    gradeRaw: () => item => {
      return _.has(item, "review.grade") ? _.round(item.review.grade, 2) : 0;
    },
    totalGradePercentage() {
      return _.isNil(this.totalGrade) ? 0 : _.round(this.totalGrade);
    },
    totalGradePercentageRaw() {
      return _.isNil(this.totalGrade) ? 0 : _.round(this.totalGrade, 2);
    }
  },
  created() {
    this.$store.commit("treinamento/currentTreinamento", {});
    this.treinamentos = [];
    this.loadTreinamentos({ action: "correcao", status: [this.selectedStatus] });
  },
  methods: {
    ...mapActions("treinamento", ["loadTreinamento", "loadTreinamentos", "resetCurrentTreinamento"]),
    ...mapActions("controller", []),
    statusFilter() {
      this.loadTreinamentos({ action: "correcao", status: [this.selectedStatus] });
    },
    corrigir(treinamento) {
      this.$log.debug(treinamento);
      this.$router.push({ name: ROUTERS.correcaoItem.name, params: { id: treinamento.id } });
    },
    formatDate(isoDate) {
      if (isoDate) {
        return new Date(isoDate).toLocaleString("pt-BR");
      }
      return null;
    }
  }
};
</script>

<style scoped src="../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
.table-date-info {
  color: #757575;
}

.data-table {
  min-width: 750px;
}
</style>
