var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        persistent: "",
        transition: "dialog-bottom-transition"
      },
      on: {
        keydown: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeDialogFunc.apply(null, arguments)
        }
      },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { staticClass: "primary darken-1", attrs: { dark: "", fixed: "" } },
            [
              _c(
                "v-toolbar-title",
                { staticClass: "text-uppercase" },
                [
                  _vm._v("Cliente - "),
                  _vm._l(_vm.cliente, function(fields, index) {
                    return fields.field === "cpf"
                      ? _c("span", [_vm._v(_vm._s(fields.originalValue))])
                      : _vm._e()
                  }),
                  _vm._v(" - \n        "),
                  _vm._l(_vm.cliente, function(fields, index) {
                    return fields.field === "nomeCompleto"
                      ? _c("span", [_vm._v(_vm._s(fields.originalValue))])
                      : _vm._e()
                  })
                ],
                2
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", icon: "" },
                  on: { click: _vm.closeDialogFunc }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("fa-times")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c("CadastroResumo", {
                attrs: {
                  cliente: _vm.cliente,
                  dossie: _vm.dossie,
                  "show-avaliacao-risco-link": false,
                  "to-avaliacao-risco": null
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }