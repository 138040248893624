<template>
  <v-container>
    <div class="d-flex justify-center mt-12">
      <v-card class="pa-1" outlined tile>
        <v-data-table 
          v-model="selected"
          :headers="headers"
          :items="dossies"          
          item-key="id"
          show-select
          :search="search"
          :custom-filter="filterText"
          class="elevation-0"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>DOSSIÊS</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                append-icon="fa-search"
                hide-details
                label="Pesquisar"
                single-line
                v-model="search"
              ></v-text-field>
              <v-spacer></v-spacer>
              <v-btn class="mx-2" fab dark small color="primary" @click="upload">
                <v-icon dark>fa-file-excel</v-icon>
              </v-btn>
              <v-btn class="mx-2" fab dark small color="primary" @click="novo">
                <v-icon dark>fa-plus</v-icon>
              </v-btn>
              <v-btn class="mx-2" fab dark small color="primary" @click="deleteAll">
                <v-icon dark>fa-trash</v-icon>
              </v-btn>
            </v-toolbar>
          </template>

          <template v-slot:item.tipoDocumento="{ item }">
            <v-chip :color="'primary'" dark v-if="item.tipoDocumento">
              {{ item.tipoDocumento }}
            </v-chip>
          </template>

          <template v-slot:item.created="{ item }">
            <span class="table-date-info">{{ formatDate(item.created) }}</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-3" @click="editItem(item)">
              fa-edit
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapDossieFields } from "@/store/modules/dossie";
import { mapActions } from "vuex";
import ROUTERS from "../../routes/router-constants";

export default {
  name: "DossieList",
  data() {
    return {
      search: "",
      selected: [],
      footer: {
        itemsPerPageOptions: [10, 25, 50],
        showCurrentPage: true,
        showFirstLastPage: true
      },
      headers: [
        { text: "Nome", align: "left", sortable: true, value: "nome" },
        { text: "Situação", align: "left", sortable: true, value: "status" },
        { text: "Tipo de documento", align: "left", sortable: true, value: "tipoDocumento" },
        { text: "Data de Inclusão", align: "left", sortable: true, value: "created" },
        { text: "Ação", align: "left", sortable: false, value: "action", width: "80px" }
      ]
    };
  },
  computed: {
    ...mapDossieFields(["dossies", "loading", "currentDossie"])
  },
  created() {
    this.dossies = [];
    this.loadDossies();
    this.currentDossie = {};
  },
  methods: {
    ...mapActions("dossie", ["loadDossie", "loadDossies", "resetCurrentDossie", "deleteDossie", "deleteAllDossie", "uploadDossie"]),
    novo() {
      this.resetCurrentDossie();
      this.$router.push({ name: ROUTERS.dossie.name, params: { id: "new" } });
    },
    upload() {
      this.resetCurrentDossie();
      this.$router.push({ name: ROUTERS.dossiesUpload.name });
    },
    editItem(dossie) {
      this.loadDossie(dossie.id).then(() => {
        this.$router.push({ name: ROUTERS.dossie.name, params: { id: dossie.id } });
      });
    },
    formatDate(isoDate) {
      if (isoDate) {
        return new Date(isoDate).toLocaleString("pt-BR");
      }
      return null;
    },

    filterText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
    },

    deleteAll() {

      if (this.selected.length == 0) {
        this.$alert.warn("Selecione os registros a serem excluidos");
        return;
      }

      this.$alert
        .confirmDelete(
          `Tem certeza de que deseja excluir ${this.selected.length} dossiês?`,
          "Você não poderá recuperar os registros"
        )
        .then(result => {
          if (result.value) {

            let ids = [];
            
            for (const dossie of this.selected) {
                ids.push(dossie.id);
            }

            this.deleteAllDossie(ids).then(() => {
              this.$alert.notifySuccess("Deletado com sucesso");
              this.selected = [];
              this.loadDossies();
            });

          }
        });
    },

    deleteItem(dossie) {
      this.$alert
        .confirmDelete(
          `Tem certeza de que deseja excluir o dossiê '${dossie.nome}'?`,
          "Você não poderá recuperar esta dossiê!"
        )
        .then(result => {
          if (result.value) {
            this.deleteDossie(dossie.id).then(() => {
              this.$alert.notifySuccess("Deletado com sucesso");
              this.loadDossies();
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.data-table {
}
</style>
