<template>
  <v-container class="d-flex">
    <div class="mx-auto">
      <v-card class="pa-1" outlined tile>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-avatar class="profile" color="grey" size="130">
              <v-img :src="profile.picture"></v-img>
            </v-avatar>

            <div class="d-flex flex-column mx-5">
              <span class="title text-uppercase">{{ profile.name }}</span>
              <span class="subtitle-2 font-weight-light">{{ profile.email }}</span>
            </div>
          </div>

          <div class="d-flex flex-column align-center">
            <template>
              <v-row>
                <v-col>
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="dateRangeText" label="Selecione período: " prepend-icon="mdi-calendar"
                        readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="dates" locale="pt-br" no-title scrollable range>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="resetTotalGrade();">
                        Limpar
                      </v-btn>
                      <v-btn text color="primary"
                        @click="$refs.menu.save(dateRangeText);totalGradeByPeriod(dateRangeText)">
                        Pesquisar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
          </div>

          <div class="d-flex flex-column align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-progress-circular :rotate="-90" :size="100" :value="totalGradePercentage" :width="11" color="primary"
                  v-on="on">
                  {{ totalGradePercentage }}
                </v-progress-circular>
              </template>
              <span>{{ totalGradePercentageRaw }}</span>
            </v-tooltip>
            <span class="subtitle-2">Nota Geral</span>
          </div>
        </div>
      </v-card>

      <div class="d-flex justify-center mt-3">
        <v-card class="pa-1" outlined tile>
          <v-data-table :footer-props="footer" :headers="headers" :items="treinamentos" :loading="loading"
            :search="search" class="data-table elevation-0" item-key="id">
            <template v-slot:top>
              <v-toolbar color="white" flat>
                <v-toolbar-title>TREINAMENTOS</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field append-icon="fa-search" hide-details label="Pesquisar" single-line v-model="search">
                </v-text-field>
                <v-spacer></v-spacer>
                <NovoTreinamentoDialog :close-dialog-func="closeDialog"
                  :criar-iniciar-treinamento-func="criarIniciarTreinamento"
                  :criar-treinamento-func="criarIniciarTreinamento" :dossie-names="dossieNames"
                  :show-dialog="showDialogNew" :show-dialog-func="showDialog" v-model="nomeDossie">
                </NovoTreinamentoDialog>
              </v-toolbar>
            </template>

            <template v-slot:item.dossie.nome="{ item }">
              <span>{{ item.dossie.nome }}</span>
              <v-tooltip bottom v-if="isCorrigido(item)">
                <template v-slot:activator="{ on }">
                  <v-icon color="green darken-2" class="ml-3" dark v-on="on">fa-check</v-icon>
                </template>
                <span>{{ item.status }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.created="{ item }">
              <span class="table-date-info">{{ formatDate(item.created) }}</span>
            </template>

            <template v-slot:item.completedAt="{ item }">
              <span class="table-date-info">{{ formatDate(item.completedAt) }}</span>
            </template>

            <template v-slot:item.action="{ item }">
              <v-tooltip bottom v-if="!isPendente(item)">
                <template v-slot:activator="{ on }">
                  <v-icon @click="view(item)" medium v-on="on">fa-search</v-icon>
                </template>
                <span>Vizualizar</span>
              </v-tooltip>

              <v-tooltip bottom v-if="isPendente(item)">
                <template v-slot:activator="{ on }">
                  <v-icon @click="resume(item)" medium v-on="on">fa-play-circle</v-icon>
                </template>
                <span>Continuar treinamento</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>

    <VisualizarTreinamentoDialog :treinamento="viewTreinamento" @close="onCloseView" v-model="showDialogView">
    </VisualizarTreinamentoDialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import { mapCacheActions } from "vuex-cache";
import { mapDossieFields } from "@/store/modules/dossie";
import { mapTreinamentoFields } from "@/store/modules/treinamento";
import NovoTreinamentoDialog from "../../components/caixaqui/NovoTreinamentoDialog";
import VisualizarTreinamentoDialog from "../../components/caixaqui/VisualizarTreinamentoDialog";
import ROUTERS from "@/routes/router-constants";
import * as moment from "moment";

export default {
  name: "Perfil",
  components: { NovoTreinamentoDialog, VisualizarTreinamentoDialog },
  data() {
    return {
      menu: false,
      dates: [],
      search: "",
      showDialogNew: false,
      showDialogView: false,
      viewTreinamento: {},
      nomeDossie: null,
      footer: {
        itemsPerPageOptions: [10, 25, 50],
        showCurrentPage: true,
        showFirstLastPage: true
      },
      headers: [
        { text: "Dossiê", align: "left", sortable: true, value: "dossie.nome" },
        { text: "Criado", align: "left", sortable: true, value: "created" },
        { text: "Finalizado", align: "left", sortable: true, value: "completedAt" },
        { text: "Situação", align: "left", sortable: true, value: "status" },
        { text: "Ações", align: "left", sortable: false, value: "action", width: "70px" }
      ]
    };
  },
  computed: {
    ...mapGetters("auth", ["profile"]),
    ...mapDossieFields(["dossieNames"]),
    ...mapTreinamentoFields(["loading", "treinamentos", "totalGrade"]),
    isCorrigido: () => item => {
      return item.status === "CORRIGIDO";
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    isPendente: () => item => {
      return item.status === "PENDENTE";
    },
    totalGradePercentage() {
      return _.isNil(this.totalGrade) ? 0 : _.round(this.totalGrade);
    },
    totalGradePercentageRaw() {
      return _.isNil(this.totalGrade) ? 0 : _.round(this.totalGrade, 2);
    }
  },
  created() {
    this.$store.commit("treinamento/currentTreinamento", {});
    this.treinamentos = [];
    this.loadDossiesNames();
    this.loadTotalGrade();
    this.loadTreinamentos();
  },
  methods: {
    ...mapCacheActions("dossie", ["loadDossiesNames"]),
    ...mapActions("treinamento", [
      "loadTreinamento",
      "loadTreinamentos",
      "resetCurrentTreinamento",
      "createAndSaveNewTreinamento",
      "loadTotalGrade",
      "loadTotalGradeByoPeriod"
    ]),
    showDialog() {
      this.nomeDossie = null;
      this.loadDossiesNames();
      this.showDialogNew = true;
    },
    closeDialog() {
      this.showDialogNew = false;
    },
    criarTreinamento() {
      this.createAndSaveNewTreinamento(this.nomeDossie).then(() => {
        this.loadTreinamentos();
        this.closeDialog();
      });
    },
    criarIniciarTreinamento() {
      this.criarTreinamento();
    },
    view(treinamento) {
      this.$log.debug("TESTE 2", treinamento);

      this.loadTreinamento(treinamento.id).then(value => {
        this.showDialogView = true;
        this.viewTreinamento = value;
      });
    },
    onCloseView() {
      this.viewTreinamento = {};
    },
    resume(treinamento) {
      this.$log.debug(treinamento);
      this.loadTreinamento(treinamento.id).then(value => {
        this.$store.commit("treinamento/currentTreinamento", value);
        this.$router.push({ name: ROUTERS.treinamento.name, params: { id: treinamento.id } });
      });
    },
    formatDate(isoDate) {
      if (isoDate) {
        return new Date(isoDate).toLocaleString("pt-BR");
      }
      return null;
    },
    totalGradeByPeriod(time) {

      let values = time.split('~').map(function (item) {
        return item.trim();
      });

      if (values.length < 2) {
        this.$alert.warn("Selecione Data Inicial e Final");
        return;
      }

      if (moment(values[0]).isAfter(values[1])) {
        this.$alert.warn("Data Final maior que Data Inicial");
        this.resetTotalGrade();
        return;
      }

      let filter = {
        "from": values[0],
        "to": values[1]
      };

      this.loadTotalGradeByoPeriod(filter);
    },
    resetTotalGrade() {
      this.menu = false;
      this.dates = [];
      this.loadTotalGrade();
    }
  }
};
</script>

<style scoped src="../../components/caixaqui/caixaqui-style.css">

</style>
<style scoped>
.table-date-info {
  color: #757575;
}
</style>
