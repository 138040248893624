<template>
  <v-container class="d-flex">
    <div class="mx-auto">
      <v-row>
        <v-col cols="12" md="12">
          <div class="text-h4 font-weight-black text-center py-5" style="color: #214498;">
            Relatório detalhado dos Treinamentos
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2">
          <v-subheader class="text-h5 font-weight-black mt-4" style="color: #214498;">Período </v-subheader>
        </v-col>
        <v-col cols="12" md="3">
          <date-input :max="filter.dateTo" class="mr-4" label="De..." v-model="filter.dateFrom" />
        </v-col>
        <v-col cols="12" md="3">
          <date-input :min="filter.dateFrom" label="Até..." v-model="filter.dateTo" />
        </v-col>
        <v-col cols="12" md="1" :class="searchButtonAlignment">
          <v-btn @click="searchReport" class="mt-4" color="primary" medium>
            Buscar
          </v-btn>
        </v-col>
        <v-col cols="12" md="3" :class="downloadButtonAlignment">
          <download-excel
            v-if="!exporting"
            class="mt-4 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            name="Relatorio_detalhado_Treinamentos.xls"
            worksheet="Excel por treinamento"
            style="cursor: pointer"
            :fetch="exportExcel"
            :before-generate="startDownload"
            :before-finish="finishDownload"
            :fields="excelFields"
            :disabled="exporting"
          >
            <span class="v-btn__content">
              <span>Exportar Excel</span>
            </span>
          </download-excel>
          <v-btn class="mt-4" color="primary" medium disabled v-if="exporting">
            <v-progress-circular indeterminate :width="1" :size="20" class="mr-2" />
            Exportando...
          </v-btn>
        </v-col>
      </v-row>

      <div class="d-flex justify-center mt-10 mb-9">
        <v-card class="pa-1" outlined tile>
          <v-data-table
            :footer-props="footer"
            :headers="headers"
            :hide-default-footer="true"
            :items="reportItems"
            :items-per-page="-1"
            :loading="loading"
            :search="search"
            class="data-table elevation-0"
            item-key="id"
          >
            <template v-slot:item.created="{ item }">
              <span class="table-date-info">{{ formatDate(item.created) }}</span>
            </template>

            <template v-slot:item.completedAt="{ item }">
              <span class="table-date-info">{{ formatDate(item.completedAt) }}</span>
            </template>

            <template v-slot:item.criticalErrorsLabel="{ item }">
              <v-chip :color="'primary'" dark v-if="item.criticalErrorsLabel">
                {{ item.criticalErrorsLabel }}
              </v-chip>
            </template>

            <template v-slot:item.nonCriticalErrorsLabel="{ item }">
              <v-chip :color="'primary'" dark v-if="item.nonCriticalErrorsLabel">
                {{ item.nonCriticalErrorsLabel }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import DateInput from "../../components/main/DateInput";
import { mapActions } from "vuex";
import JsonExcel from "vue-json-excel";

export default {
  name: "Relatorio",
  components: { DateInput, downloadExcel: JsonExcel },
  comments: {
    "date-input": DateInput
  },
  data() {
    return {
      filter: {
        dateFrom: null,
        dateTo: null
      },
      loading: false,
      exporting: false,
      search: "",
      reportItems: [],
      footer: {
        itemsPerPageOptions: [-1],
        showCurrentPage: true,
        showFirstLastPage: true
      },
      tableHeaders: [
        { text: "Aluno", align: "left", sortable: false, value: "student" },
        { text: "Dossiê", align: "left", sortable: false, value: "dossier" },
        {
          text: "Data Inicial",
          align: "left",
          sortable: false,
          value: "created"
        },
        {
          text: "Data Final",
          align: "left",
          sortable: false,
          value: "completedAt"
        },
        { text: "Tempo Duração", align: "left", sortable: false, value: "duration" },
        { text: "Erros Críticos", align: "center", sortable: false, value: "criticalErrorsLabel" },
        {
          text: "Erros Não Críticos",
          align: "center",
          sortable: false,
          value: "nonCriticalErrorsLabel"
        },
        { text: "Qtde de Campos Preenchidos", align: "left", sortable: false, value: "filledFields" },
        {
          text: "Qtde de Erros Críticos",
          align: "left",
          sortable: false,
          value: "criticalErrors"
        },
        {
          text: "Qtde de Erros Não Críticos",
          align: "left",
          sortable: false,
          value: "nonCriticalErrors"
        }
      ],
      excelData: []
    };
  },
  computed: {
    searchButtonAlignment() {
      return this.$vuetify.breakpoint.mobile ? "text-center" : "";
    },
    downloadButtonAlignment() {
      return this.$vuetify.breakpoint.mobile ? "text-center" : "text-right";
    },
    headers() {
      return this.tableHeaders;
    },
    excelFields() {
      // noinspection JSNonASCIINames,NonAsciiCharacters
      return {
        "Login do aluno": { field: "studentLogin" },
        "Nome do aluno": { field: "studentName" },
        Dossiê: { field: "dossier" },
        "Data de extração do excel": { field: "dateOfExtraction", callback: this.formatDate },
        "Data Início": { field: "created", callback: this.formatDate },
        "Data Final": { field: "completedAt", callback: this.formatDate },
        "Tempo de Duração": { field: "duration" },
        "Erros Críticos": { field: "criticalErrorsLabel" },
        "Erros Não Críticos": { field: "nonCriticalErrorsLabel" },
        "Qtde de Campos Preenchidos": { field: "filledFields" },
        "Qtde de Erros Críticos": { field: "criticalErrors" },
        "Qtde de Erros Não Críticos": { field: "nonCriticalErrors" }
      };
    }
  },
  created() {},
  methods: {
    ...mapActions("reports", ["generateReport", "exportReport"]),
    formatDate(isoDate) {
      if (isoDate) {
        return new Date(isoDate).toLocaleString("pt-BR");
      }
      return null;
    },
    searchReport() {
      this.reportItems = [];
      this.loading = true;
      this.generateReport({ from: this.filter.dateFrom, to: this.filter.dateTo })
        .then(value => {
          this.reportItems = value.items;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async exportExcel() {
      const response = await this.exportReport({ from: this.filter.dateFrom, to: this.filter.dateTo });
      return response.items;
    },
    startDownload() {
      this.exporting = true;
    },
    finishDownload() {
      this.exporting = false;
    }
  }
};
</script>

<style scoped src="../../components/caixaqui/caixaqui-style.css"></style>
<style scoped>
.table-date-info {
  color: #757575;
}
</style>
