<template>
  <v-container fluid>
    <v-row class="mb-6" no-gutters>
      <v-col>
        <img :src="profile.picture" alt="User's profile picture" class="rounded-circle img-fluid profile-picture" />
      </v-col>

      <v-col>
        <div class="mt-5">
          <h2>{{ profile.name }}</h2>
          <p class="lead text-muted">{{ profile.email }}</p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <pre v-highlight class="rounded full-width"><code class="json">{{ JSON.stringify(profile, null, 2) }}</code></pre>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueCodeHighlight from "vue-code-highlight";

Vue.use(VueCodeHighlight);

export default {
  name: "Profile",
  computed: {
    profile() {
      return this.$store.getters["auth/profile"];
    }
  }
};
</script>
