var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px", persistent: "" },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { staticClass: "primary darken-1", attrs: { dark: "" } },
                [
                  _c("v-toolbar-title", [_vm._v("Detalhes do Treinamento")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", icon: "" },
                      on: { click: _vm.closeDialogFunc }
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("fa-times")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-list",
                        { attrs: { "three-line": "" } },
                        [
                          _vm._l(_vm.clientes, function(item, index) {
                            return [
                              index === 0
                                ? _c("v-subheader", {
                                    key: `${item.cpf}-divider-subheader`,
                                    domProps: {
                                      textContent: _vm._s("Clientes")
                                    }
                                  })
                                : _vm._e(),
                              index !== 0
                                ? _c("v-divider", {
                                    key: `${item.cpf}-divider`
                                  })
                                : _vm._e(),
                              _c(
                                "v-list-item",
                                {
                                  key: item.valueReviews[5].originalValue,
                                  on: {
                                    click: function($event) {
                                      return _vm.viewCliente(
                                        item,
                                        index,
                                        _vm.dossies
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    [_c("v-icon", [_vm._v("fa-user")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _vm._l(item.valueReviews, function(
                                        fields,
                                        index
                                      ) {
                                        return fields.field === "cpf"
                                          ? _c("v-list-item-title", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  fields.originalValue
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      }),
                                      _vm._l(item.valueReviews, function(
                                        fields,
                                        index
                                      ) {
                                        return fields.field === "nomeCompleto"
                                          ? _c("v-list-item-subtitle", {
                                              staticClass: "text-uppercase",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  fields.originalValue
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ]
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-list",
                        { attrs: { "three-line": "" } },
                        [
                          [
                            _c("v-subheader", {
                              domProps: {
                                textContent: _vm._s("Avaliação de Risco")
                              }
                            }),
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.viewAvaliacaoRisco(
                                      _vm.avaliacaoRisco
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-list-item-avatar",
                                  [_c("v-icon", [_vm._v("fa-balance-scale")])],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.avaliacaoRisco.cpf
                                        )
                                      }
                                    }),
                                    _c("v-list-item-subtitle", {
                                      staticClass: "text-uppercase",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.avaliacaoRisco.nomeCompleto
                                        )
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ],
                        2
                      ),
                      _c(
                        "v-list",
                        { attrs: { "three-line": "" } },
                        [
                          _vm.respostaAssino[0] !== undefined
                            ? [
                                _c("v-subheader", {
                                  domProps: {
                                    textContent: _vm._s("Resposta Assino")
                                  }
                                }),
                                _c(
                                  "v-list-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.viewRespostaAssino(
                                          _vm.respostaAssino
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list-item-avatar",
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-balance-scale")
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.respostaAssino[0]
                                                ?.originalValue
                                            )
                                          }
                                        }),
                                        _c("v-list-item-subtitle", {
                                          staticClass: "text-uppercase",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.respostaAssino[7]
                                                ?.originalValue
                                            )
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("VisualizarClienteDialog", {
        attrs: {
          cliente: _vm.clienteSelecionado,
          dossie: _vm.dossieSelecionado,
          close: _vm.onClose
        },
        model: {
          value: _vm.showCliente,
          callback: function($$v) {
            _vm.showCliente = $$v
          },
          expression: "showCliente"
        }
      }),
      _c("VisualizarAvaliacaoRiscoDialog", {
        attrs: {
          "avaliacao-risco": _vm.avaliacaoRiscoSelecionado,
          close: _vm.onClose
        },
        model: {
          value: _vm.showAvaliacaoRisco,
          callback: function($$v) {
            _vm.showAvaliacaoRisco = $$v
          },
          expression: "showAvaliacaoRisco"
        }
      }),
      _c("VisualizarRespostaAssinoDialog", {
        attrs: {
          "resposta-assino": _vm.respostaAssinoSelecionado,
          close: _vm.onClose
        },
        model: {
          value: _vm.showRespostaAssino,
          callback: function($$v) {
            _vm.showRespostaAssino = $$v
          },
          expression: "showRespostaAssino"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }