var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "d-flex justify-center mt-12" },
      [
        _c(
          "v-form",
          { staticClass: "form-size" },
          [
            _c("v-file-input", {
              staticClass: "d-flex",
              attrs: {
                "error-messages": _vm.fileErrors,
                label: "Arquivo de Dossiê",
                "prepend-icon": "fa-file-excel",
                accept: ".xls,.xlsx,.csv",
                required: "",
                outlined: "",
                counter: "",
                "show-size": 1000
              },
              model: {
                value: _vm.file,
                callback: function($$v) {
                  _vm.file = $$v
                },
                expression: "file"
              }
            }),
            _c("v-btn", { staticClass: "mr-4", on: { click: _vm.upload } }, [
              _vm._v("Enviar")
            ]),
            _c("v-btn", { staticClass: "mr-4", on: { click: _vm.cancelar } }, [
              _vm._v("Cancelar")
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }