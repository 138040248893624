var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex" },
    [
      _c(
        "div",
        { staticClass: "mx-auto" },
        [
          _c(
            "v-card",
            { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "profile",
                          attrs: { color: "grey", size: "130" }
                        },
                        [_c("v-img", { attrs: { src: _vm.profile.picture } })],
                        1
                      ),
                      _c("div", { staticClass: "d-flex flex-column mx-5" }, [
                        _c("span", { staticClass: "title text-uppercase" }, [
                          _vm._v(_vm._s(_vm.profile.name))
                        ]),
                        _c(
                          "span",
                          { staticClass: "subtitle-2 font-weight-light" },
                          [_vm._v(_vm._s(_vm.profile.email))]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "menu",
                                    attrs: {
                                      "close-on-content-click": false,
                                      transition: "scale-transition",
                                      "offset-y": "",
                                      "min-width": "auto"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      label:
                                                        "Selecione período: ",
                                                      "prepend-icon":
                                                        "mdi-calendar",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value: _vm.dateRangeText,
                                                      callback: function($$v) {
                                                        _vm.dateRangeText = $$v
                                                      },
                                                      expression:
                                                        "dateRangeText"
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.menu,
                                      callback: function($$v) {
                                        _vm.menu = $$v
                                      },
                                      expression: "menu"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-date-picker",
                                      {
                                        attrs: {
                                          locale: "pt-br",
                                          "no-title": "",
                                          scrollable: "",
                                          range: ""
                                        },
                                        model: {
                                          value: _vm.dates,
                                          callback: function($$v) {
                                            _vm.dates = $$v
                                          },
                                          expression: "dates"
                                        }
                                      },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.resetTotalGrade()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Limpar\n                    "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.$refs.menu.save(
                                                  _vm.dateRangeText
                                                )
                                                _vm.totalGradeByPeriod(
                                                  _vm.dateRangeText
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Pesquisar\n                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column align-center" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "v-progress-circular",
                                    _vm._g(
                                      {
                                        attrs: {
                                          rotate: -90,
                                          size: 100,
                                          value: _vm.totalGradePercentage,
                                          width: 11,
                                          color: "primary"
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.totalGradePercentage) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.totalGradePercentageRaw))
                          ])
                        ]
                      ),
                      _c("span", { staticClass: "subtitle-2" }, [
                        _vm._v("Nota Geral")
                      ])
                    ],
                    1
                  )
                ]
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex justify-center mt-3" },
            [
              _c(
                "v-card",
                { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
                [
                  _c("v-data-table", {
                    staticClass: "data-table elevation-0",
                    attrs: {
                      "footer-props": _vm.footer,
                      headers: _vm.headers,
                      items: _vm.treinamentos,
                      loading: _vm.loading,
                      search: _vm.search,
                      "item-key": "id"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-toolbar",
                              { attrs: { color: "white", flat: "" } },
                              [
                                _c("v-toolbar-title", [_vm._v("TREINAMENTOS")]),
                                _c("v-spacer"),
                                _c("v-text-field", {
                                  attrs: {
                                    "append-icon": "fa-search",
                                    "hide-details": "",
                                    label: "Pesquisar",
                                    "single-line": ""
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search"
                                  }
                                }),
                                _c("v-spacer"),
                                _c("NovoTreinamentoDialog", {
                                  attrs: {
                                    "close-dialog-func": _vm.closeDialog,
                                    "criar-iniciar-treinamento-func":
                                      _vm.criarIniciarTreinamento,
                                    "criar-treinamento-func":
                                      _vm.criarIniciarTreinamento,
                                    "dossie-names": _vm.dossieNames,
                                    "show-dialog": _vm.showDialogNew,
                                    "show-dialog-func": _vm.showDialog
                                  },
                                  model: {
                                    value: _vm.nomeDossie,
                                    callback: function($$v) {
                                      _vm.nomeDossie = $$v
                                    },
                                    expression: "nomeDossie"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.dossie.nome",
                        fn: function({ item }) {
                          return [
                            _c("span", [_vm._v(_vm._s(item.dossie.nome))]),
                            _vm.isCorrigido(item)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    staticClass: "ml-3",
                                                    attrs: {
                                                      color: "green darken-2",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-check")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.status))])]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.created",
                        fn: function({ item }) {
                          return [
                            _c("span", { staticClass: "table-date-info" }, [
                              _vm._v(_vm._s(_vm.formatDate(item.created)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.completedAt",
                        fn: function({ item }) {
                          return [
                            _c("span", { staticClass: "table-date-info" }, [
                              _vm._v(_vm._s(_vm.formatDate(item.completedAt)))
                            ])
                          ]
                        }
                      },
                      {
                        key: "item.action",
                        fn: function({ item }) {
                          return [
                            !_vm.isPendente(item)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: { medium: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.view(item)
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-search")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [_c("span", [_vm._v("Vizualizar")])]
                                )
                              : _vm._e(),
                            _vm.isPendente(item)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: { medium: "" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.resume(item)
                                                      }
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-play-circle")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Continuar treinamento")
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("VisualizarTreinamentoDialog", {
        attrs: { treinamento: _vm.viewTreinamento },
        on: { close: _vm.onCloseView },
        model: {
          value: _vm.showDialogView,
          callback: function($$v) {
            _vm.showDialogView = $$v
          },
          expression: "showDialogView"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }