<template>
  <v-dialog max-width="500px" persistent v-model="showDialog">
    <template v-slot:activator="{ on }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn @click="showDialogFunc" class="mx-2" color="primary" dark fab small v-on="on">
            <v-icon dark>fa-plus</v-icon>
          </v-btn>
        </template>
        <span>Novo Treinamento</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar class="primary darken-1" dark>
        <v-toolbar-title>Novo Treinamento</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialogFunc" dark icon>
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-combobox
            :items="dossieNames"
            :value="value"
            @input="updateNomeDossieInput"
            append-icon=""
            clearable
            hide-details
            hide-no-data
            label="Nome do Dossiê"
          >
          </v-combobox>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="criarTreinamentoFunc" color="primary darken-1" outlined small>Criar</v-btn>
        <v-btn @click="criarIniciarTreinamentoFunc" color="primary darken-1" outlined small>Criar e Iniciar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NovoTreinamentoDialog",
  props: [
    "showDialog",
    "showDialogFunc",
    "closeDialogFunc",
    "dossieNames",
    "value",
    "criarTreinamentoFunc",
    "criarIniciarTreinamentoFunc"
  ],
  methods: {
    updateNomeDossieInput: function(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style scoped></style>
