import _ from "lodash";

const telasFieldCliente = [
  { nome: "Dados Cadastrais", fieldPrefix: "cpf" },
  { nome: "Dados Cadastrais", fieldPrefix: "numeroDependentes" },
  { nome: "Dados Cadastrais", fieldPrefix: "declaracaoPropositos" },
  { nome: "Dados Cadastrais", fieldPrefix: "nomeCompleto" },
  { nome: "Dados Cadastrais", fieldPrefix: "nomeReduzido" },
  { nome: "Dados Cadastrais", fieldPrefix: "dataNascimento" },
  { nome: "Dados Cadastrais", fieldPrefix: "sexo" },
  { nome: "Dados Cadastrais", fieldPrefix: "nacionalidade" },
  { nome: "Dados Cadastrais", fieldPrefix: "pais" },
  { nome: "Dados Cadastrais", fieldPrefix: "naturalidade.uf" },
  { nome: "Dados Cadastrais", fieldPrefix: "naturalidade.municipio" },
  { nome: "Dados Cadastrais", fieldPrefix: "nomePai" },
  { nome: "Dados Cadastrais", fieldPrefix: "nomeMae" },
  { nome: "Dados Cadastrais", fieldPrefix: "grauInstrucao" },
  { nome: "Dados Cadastrais", fieldPrefix: "pisNis" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.value" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.numero" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.orgaoEmissor" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.uf" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.dataEmissao" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.dataFimValidade" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.documentoIdentificacao" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.passaporte" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoDocumento.cnh" },
  { nome: "Dados Cadastrais", fieldPrefix: "estadoCivil" },
  { nome: "Dados Cadastrais", fieldPrefix: "tipoOcupacao" },
  { nome: "Dados Cadastrais", fieldPrefix: "ocupacao" },
  { nome: "Dados Cadastrais", fieldPrefix: "conjuge.cpf" },
  { nome: "Dados Cadastrais", fieldPrefix: "conjuge.nome" },

  { nome: "Endereço", fieldPrefix: "endereco.cep" },
  { nome: "Endereço", fieldPrefix: "endereco.tipoLogradouro" },
  { nome: "Endereço", fieldPrefix: "endereco.logradouro" },
  { nome: "Endereço", fieldPrefix: "endereco.complemento" },
  { nome: "Endereço", fieldPrefix: "endereco.numero" },
  { nome: "Endereço", fieldPrefix: "endereco.bairro" },
  { nome: "Endereço", fieldPrefix: "endereco.uf" },
  { nome: "Endereço", fieldPrefix: "endereco.cidade" },
  { nome: "Endereço", fieldPrefix: "endereco.tipoImovel" },
  { nome: "Endereço", fieldPrefix: "endereco.ocupacaoImovel" },
  { nome: "Endereço", fieldPrefix: "endereco.comprovanteResidenciaMes" },
  { nome: "Endereço", fieldPrefix: "endereco.comprovanteResidenciaAno" },

  { nome: "Telefones", fieldPrefix: "telefones.contato" },
  { nome: "Telefones", fieldPrefix: "telefones.celular" },
  { nome: "Telefones", fieldPrefix: "telefones.recado" },

  { nome: "E-mail", fieldPrefix: "email" },

  { nome: "Renda Formal", fieldPrefix: "rendasFormais" },

  { nome: "Renda Informal", fieldPrefix: "rendasInformais" },

  { nome: "Patrimônio Consolidado", fieldPrefix: "patrimonioConsolidado.valorPatrimonio" },

  { nome: "Patrimônios - Imóveis", fieldPrefix: "patrimonios.imoveis" },
  { nome: "Patrimônios - Veículos", fieldPrefix: "patrimonios.veiculos" },
  { nome: "Patrimônios - Contas", fieldPrefix: "patrimonios.contas" },
  { nome: "Patrimônios - Aplicações", fieldPrefix: "patrimonios.aplicacoes" },

  { nome: "Compromissos Financeiros", fieldPrefix: "compromissosFinanceiros" },

  { nome: "Cartões de Crédito", fieldPrefix: "cartoesCredito" },

  { nome: "Despesas Mensais", fieldPrefix: "despesasMensais.aluguel" },
  { nome: "Despesas Mensais", fieldPrefix: "despesasMensais.condominio" },
  { nome: "Despesas Mensais", fieldPrefix: "despesasMensais.pensaoAlimenticia" },

  { nome: "Renda dos Dependentes", fieldPrefix: "dependentes.quantidadeComRenda" },
  { nome: "Renda dos Dependentes", fieldPrefix: "dependentes.quantidadeSemRenda" },
  { nome: "Renda dos Dependentes", fieldPrefix: "dependentes.totalLiquidoRendimentos" },
  { nome: "Renda dos Dependentes", fieldPrefix: "dependentes.totalBrutoRendimentos" },

  { nome: "Agência de Relacionamento", fieldPrefix: "agenciaRelacionamento.uf" },
  { nome: "Agência de Relacionamento", fieldPrefix: "agenciaRelacionamento.municipio" },
  { nome: "Agência de Relacionamento", fieldPrefix: "agenciaRelacionamento.codigoNomeAgencia" }
];

const telaOrder = _.chain(telasFieldCliente)
  .map(t => t.nome)
  .uniq()
  .value();

function getTelaOrder(tela) {
  return _.indexOf(telaOrder, tela);
}

function getFiledOrder(record) {
  return _.indexOf(telasFieldCliente, record);
}

function getTelaFromFieldPrefix(field) {
  const record = _.find(telasFieldCliente, o => _.startsWith(field, o.fieldPrefix));
  if (_.isNil(record)) {
    return { nome: "", fieldPrefix: "", telaOrder: 0, fieldOrder: 0 };
  }
  return { ...record, telaOrder: getTelaOrder(record.nome), fieldOrder: getFiledOrder(record) };
}

export { getTelaFromFieldPrefix as getTelaCliente };
