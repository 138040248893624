<template>
  <v-container>
    <div class="d-flex justify-center">
      <v-form class="form-size">
        <v-text-field
          :error-messages="nomeErrors"
          @blur="$v.nome.$touch()"
          @input="$v.nome.$touch()"
          label="Nome do Dossiê"
          required
          outlined
          dense
          v-model="nome"
          class="d-flex"
        ></v-text-field>

        <v-text-field
          v-for="item in inputClientes"
          :key="item.index"
          :label="`CPF Cadastro ${item.index + 1} ${item.nome ? '- ' + item.nome.toUpperCase() : ''}`"
          v-model="item.cpf"
          readonly
          outlined
          dense
          class="input-size"
        >
          <template v-slot:append-outer>
            <v-icon small class="mr-3" @click="editCliente(item.cliente)">
              fa-edit
            </v-icon>
            <v-icon small @click="deleteCliente(item.cliente)">
              fa-trash
            </v-icon>
          </template>
        </v-text-field>

        <v-text-field
          :label="avaliacaoRiscoLabel"
          class="input-size"
          dense
          outlined
          readonly
          v-model="avaliacaoRiscoCpf"
        >
          <template v-slot:append-outer>
            <v-icon small class="mr-3" @click="editAvaliacaoRisco">
              fa-edit
            </v-icon>
            <v-icon small @click="deleteAvaliacaoRisco">
              fa-trash
            </v-icon>
          </template>
        </v-text-field>

        <v-radio-group
          :error-messages="statusErrors"
          @blur="$v.select.$touch()"
          label="Status: "
          required
          row
          v-model="status"
        >
          <v-radio label="Rascunho" value="RASCUNHO"></v-radio>
          <v-radio label="Ativo" value="ATIVO"></v-radio>
          <v-radio label="Inativo" value="INATIVO"></v-radio>
        </v-radio-group>

        <v-btn @click="salvar" class="mr-4">Salvar</v-btn>
        <v-btn @click="cancelar" class="mr-4">Cancelar</v-btn>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapCurrentDossieFields } from "@/store/modules/dossie";
import { mapActions } from "vuex";
import ROUTERS from "../../routes/router-constants";

export default {
  mixins: [validationMixin],

  name: "DossieItem",
  data: () => ({}),
  validations: {
    nome: { required },
    status: { required }
  },
  created() {
    this.loadIfNeeded();
  },
  computed: {
    ...mapCurrentDossieFields(["id", "nome", "status", "clientes", "avaliacaoRisco"]),
    inputClientes() {
      const result = [];
      for (let i of _.range(4)) {
        if (this.clientes && this.clientes[i]) {
          result.push({
            index: i,
            cpf: this.clientes[i].cpf,
            nome: this.clientes[i].nomeCompleto,
            cliente: this.clientes[i]
          });
        } else {
          result.push({ index: i, cpf: null, nome: null, cliente: null });
        }
      }
      return result;
    },
    avaliacaoRiscoCpf() {
      return _.has(this.avaliacaoRisco, "cpf") ? this.avaliacaoRisco.cpf : null;
    },
    avaliacaoRiscoLabel() {
      return `Avaliação de Risco ${
        _.has(this.avaliacaoRisco, "nomeCompleto") ? "- " + this.avaliacaoRisco.nomeCompleto.toUpperCase() : ""
      }`;
    },
    nomeErrors() {
      const errors = [];
      if (!this.$v.nome.$dirty) return errors;
      !this.$v.nome.required && errors.push("Nome do Dossiê é obrigatório.");
      return errors;
    },
    statusErrors() {
      const errors = [];
      if (!this.$v.status.$dirty) return errors;
      !this.$v.status.required && errors.push("Status é obrigatório.");
      return errors;
    }
  },
  methods: {
    ...mapActions("dossie", ["resetCurrentDossie", "saveCurrentDossie", "loadDossie", "removeClient"]),
    ...mapActions("controller", [
      "dossieNovoCliente",
      "dossieEditCliente",
      "dossieNovaAvaliacaoRisco",
      "dossieEditAvaliacaoRisco"
    ]),
    loadIfNeeded() {
      this.$log.debug("this.$route.params.id", this.$route.params.id);
      if (this.$route.params.id && this.id !== this.$route.params.id && this.$route.params.id !== "new") {
        this.loadDossie(this.$route.params.id);
      }
    },
    salvar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.saveCurrentDossie().then(() => {
          this.$alert.notifySuccess("Salvo com sucesso!");
        });
      }
    },
    cancelar() {
      this.$v.$reset();
      this.resetCurrentDossie();
      this.$router.push({ name: ROUTERS.dossies.name });
    },
    getRoute: function() {
      return { name: ROUTERS.dossie.name, params: { id: this.$route.params.id } };
    },
    editCliente(cliente) {
      this.$log.debug(cliente);
      const data = {
        sourceRoute: { name: this.$route.name, params: this.$route.params },
        router: this.$router
      };

      if (cliente) {
        this.dossieEditCliente({ ...data, cliente: cliente });
      } else {
        this.dossieNovoCliente(data);
      }
    },
    deleteCliente(cliente) {
      this.$log.debug(cliente);
      if (cliente) {
        this.removeClient(cliente);
      }
    },
    editAvaliacaoRisco() {
      const avaliacaoRisco = this.avaliacaoRisco;
      this.$log.debug(avaliacaoRisco);

      const data = {
        sourceRoute: { name: this.$route.name, params: this.$route.params },
        router: this.$router
      };

      if (avaliacaoRisco) {
        this.dossieEditAvaliacaoRisco({ ...data, avaliacaoRisco: avaliacaoRisco });
      } else {
        this.dossieNovaAvaliacaoRisco(data);
      }
    },
    deleteAvaliacaoRisco() {
      this.$log.debug(this.avaliacaoRisco);
      this.avaliacaoRisco = { cpf: null };
    }
  }
};
</script>

<style scoped>
.form-size {
  width: 800px;
}

.input-size {
  width: 500px;
}
</style>
