import { render, staticRenderFns } from "./CorrigirTreinamentoView.vue?vue&type=template&id=4c444dc0&scoped=true&"
import script from "./CorrigirTreinamentoView.vue?vue&type=script&lang=js&"
export * from "./CorrigirTreinamentoView.vue?vue&type=script&lang=js&"
import style0 from "./CorrigirTreinamentoView.vue?vue&type=style&index=0&id=4c444dc0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c444dc0",
  null
  
)

/* custom blocks */
import block0 from "./CorrigirTreinamentoView.vue?vue&type=custom&index=0&blockType=br"
if (typeof block0 === 'function') block0(component)
import block1 from "./CorrigirTreinamentoView.vue?vue&type=custom&index=1&blockType=br"
if (typeof block1 === 'function') block1(component)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c444dc0')) {
      api.createRecord('4c444dc0', component.options)
    } else {
      api.reload('4c444dc0', component.options)
    }
    module.hot.accept("./CorrigirTreinamentoView.vue?vue&type=template&id=4c444dc0&scoped=true&", function () {
      api.rerender('4c444dc0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/caixaqui/CorrigirTreinamentoView.vue"
export default component.exports