<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main" id="resumo">
    <!-- DADOS DE IDENTIFICAÇÃO-->
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Dados de Identificação</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Protocolo
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        000000000
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Código do Correspondente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        000000000
      </v-col>
    </v-row>

    <!-- DECLARAÇÃO DE PROPÓSITOS -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Declaração de Propósitos</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-center">
            <input v-for="(fields, index) in cliente"
              v-if="fields.field === 'declaracaoPropositos.movimentacaoContaDepositoPoupanca'" disabled
              id="movimentaContaDepositoPoupanca" name="movimentaContaDepositoPoupanca" type="checkbox"
              v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="movimentaContaDepositoPoupanca">Movimentação de Conta de
              Depósito/Poupança</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input v-for="(fields, index) in cliente"
              v-if="fields.field === 'declaracaoPropositos.emprestimosFinanciamentos'" disabled
              id="emprestimosFinanciamentos" name="emprestimosFinanciamentos" type="checkbox"
              v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="emprestimosFinanciamentos">Empréstimos/Financiamentos</label>
          </div>

          <div class="d-flex flex-row align-center justify-center" id="habitacional">
            <label class="form-checkbox-label">Financiamento Habitacional</label>
            <input v-for="(fields, index) in cliente"
              v-if="fields.field === 'declaracaoPropositos.financiamentoHabitacional'" disabled id="habitacionalSIM"
              name="habitacional" type="radio" v-bind:value="true" v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="habitacionalSIM">SIM</label>
            <input v-for="(fields, index) in cliente"
              v-if="fields.field === 'declaracaoPropositos.financiamentoHabitacional'" disabled id="habitacionalNAO"
              name="habitacional" type="radio" v-bind:value="false" v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="habitacionalNAO">NÃO</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input v-for="(fields, index) in cliente" v-if="fields.field === 'declaracaoPropositos.investimentos'"
              disabled id="investimentos" name="investimentos" type="checkbox" v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="investimentos">Investimentos</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input v-for="(fields, index) in cliente" v-if="fields.field === 'declaracaoPropositos.cartaoCredito'"
              disabled id="cartaoCredito" name="cartaoCredito" type="checkbox" v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="cartaoCredito">Cartão de Crédito</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input v-for="(fields, index) in cliente"
              v-if="fields.field === 'declaracaoPropositos.seguroPrevidenciaCapitalizacaoConsorcios'" disabled
              id="seguroPrevidenciaCapitalizacaoConsorcios" name="seguroPrevidenciaCapitalizacaoConsorcios"
              type="checkbox" v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="seguroPrevidenciaCapitalizacaoConsorcios">Seguros/Previdência
              Privada/Capitalização/Consórcios</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input v-for="(fields, index) in cliente"
              v-if="fields.field === 'declaracaoPropositos.operacoesInternacionaisCambio'" disabled
              id="operacoesInternacionaisCambio" name="operacoesInternacionaisCambio" type="checkbox"
              v-model="fields.originalValue" />
            <label class="form-checkbox-label" for="operacoesInternacionaisCambio">Operações
              Internacionais/Câmbio</label>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- DADOS CADASTRAIS -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Dados Cadastrais</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CPF do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente" v-if='selectedField.field === "cpf"'>
          {{ selectedField.currentValue }}
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome Completo do Cliente
      </v-col>

      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "nomeCompleto"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome Reduzido do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "nomeReduzido"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Data de Nascimento
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']"
        cols="3">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "dataNascimento"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue.substring(8)
            }}/{{ selectedField.currentValue.substring(5, 7) }}/{{
    selectedField.currentValue.substring(0, 4)
    }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ? `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue.substring(8) }}/{{
                selectedField.originalValue.substring(5, 7)
            }}/{{ selectedField.originalValue.substring(0, 4) }}</span>
          </div>
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
        Sexo
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="3">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente" v-if='selectedField.field === "sexo"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nacionalidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "nacionalidade"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Naturalidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "naturalidade.municipio"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}/ </span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "naturalidade.uf"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>
            {{ selectedField.currentValue }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome do Pai
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente" v-if='selectedField.field === "nomePai"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome do Mãe
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente" v-if='selectedField.field === "nomeMae"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Grau de Instrução
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "grauInstrucao"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        PIS/NIS
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente" v-if='selectedField.field === "pisNis"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Documento de Identidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.value"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="isDocumentoIdentificacaoVisible(cliente)">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Subtipo do Documento
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.documentoIdentificacao.subTipo"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        <span v-if="isPassaporteVisible">Número do Passaporte</span>
        <span v-if="isCnhVisible">Número da Carteira Nacional de Habilitação</span>
        <span v-else>Número da Identidade</span>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.numero"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Órgão Emissor
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.orgaoEmissor"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        UF
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.uf"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="isPassaporteVisible">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        País de origem
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.passaporte.paisOrigem"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="isFirstCnhVisible">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Data 1ª Habilitação
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.dataPrimeiraHabilitacao"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>
            {{ selectedField.currentValue.substring(8) }}/{{ selectedField.currentValue.substring(5, 7) }}/{{
                selectedField.currentValue.substring(0, 4)
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?
                `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue !== "" ?
                `${selectedField.originalValue.substring(8)}/${selectedField.originalValue.substring(5, 7)}/${selectedField.originalValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Data de Emissão
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="2">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.dataEmissao"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue.substring(8)
            }}/{{ selectedField.currentValue.substring(5, 7) }}/{{
    selectedField.currentValue.substring(0, 4)
    }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?
                `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue !== "" ?
                `${selectedField.originalValue.substring(8)}/${selectedField.originalValue.substring(5, 7)}/${selectedField.originalValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'text-no-wrap', 'form-fim-val-label']"
        cols="3">
        Data Fim Validade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="3">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "tipoDocumento.dataFimValidade"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{ selectedField.currentValue !== "" ?
                `${selectedField.currentValue.substring(8)}/${ selectedField.currentValue.substring(5, 7) }/${selectedField.currentValue.substring(0, 4)}` : ""
    }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?
                `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue !== "" ?
                `${selectedField.originalValue.substring(8)}/${selectedField.originalValue.substring(5, 7)}/${selectedField.originalValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Estado Civil
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "estadoCivil"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Ocupação
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "ocupacao"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- CONJUGE -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Dados do Conjuge</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CPF
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "conjuge.cpf"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "conjuge.nome"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- ENDEREÇO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Endereço</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CEP
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.cep"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Logradouro
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.logradouro"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red" v-for="(field, index) in cliente"
              v-if='field.field === "endereco.tipoLogradouro"'>{{ field.currentValue !== "" ? field.currentValue + " " : "(VAZIO)" }}</span>
            <span style="color:red">{{ selectedField.currentValue !== "" ? selectedField.currentValue : "" }} </span>
            &#x27A4
            <span style="color:green" v-for="(field, index) in cliente"
              v-if='field.field === "endereco.tipoLogradouro"'>{{ field.originalValue + " " }}</span>
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Complemento
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left', 'text-uppercase']"
        cols="5">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.complemento"'>
          <span>{{ selectedField.currentValue }}</span>
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
        Número
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="1">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.numero"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Município
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left', 'text-uppercase']"
        cols="5">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.cidade"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
        Estado
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="1">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.uf"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Bairro
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.bairro"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Imóvel
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.tipoImovel"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Ocupação do Imóvel
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.ocupacaoImovel"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Comprovante de Residência
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "endereco.comprovanteResidenciaMes"'>
          <div v-if='isValidDate'>
            <span>{{ selectedField.currentValue !== "" ? `${selectedField.currentValue} /` : "(VAZIO)" }}</span>
            <span v-for="(field, index) in cliente" v-if='field.field === "endereco.comprovanteResidenciaAno"'>{{
                field.currentValue
            }}</span>
          </div>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ? `${selectedField.currentValue} /` : "(VAZIO)" }}</span>
            <span style="color:red" v-for="(field, index) in cliente"
              v-if='field.field === "endereco.comprovanteResidenciaAno"'>{{ field.currentValue }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}/</span>
            <span style="color:green" v-for="(field, index) in cliente"
              v-if='field.field === "endereco.comprovanteResidenciaAno"'>{{ field.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- TELEFONES -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Telefones</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Telefone de Contato
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="6">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "telefones.contato.ddd"'>
          <div v-if='isValidPhoneNumber'>
            <span>{{ selectedField.currentValue !== "" ?  `(${selectedField.currentValue})` : "(VAZIO)"}}</span>
            <span v-for="(field, index) in cliente" v-if='field.field === "telefones.contato.telefone"'>{{ " " +
                field.currentValue
            }}</span>
          </div>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  `(${selectedField.currentValue})` : "(VAZIO)"}}</span>
            <span style="color:red" v-for="(field, index) in cliente"
              v-if='field.field === "telefones.contato.telefone"'>{{ " " + field.currentValue }}</span>
            &#x27A4
            <span style="color:green">({{ selectedField.originalValue }})</span>
            <span style="color:green" v-for="(field, index) in cliente"
              v-if='field.field === "telefones.contato.telefone"'>{{ " " + field.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Telefone Celular
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="6">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "telefones.celular.ddd"'>
          <div v-if='selectedField.currentValue !== ""'>
            <span>({{ selectedField.currentValue }})</span>
            <span v-for="(field, index) in cliente" v-if='field.field === "telefones.celular.telefone"'>{{ " " +
                field.currentValue
            }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- RENDA FORMAL -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Renda Formal</div>
      </v-col>
    </v-row>
    <div v-for="(doc, index) in dossie">
      <div v-if="isCaracteristicaNotSemRendaResult(index)">
        <v-row class="form-row-result resumo-list form-row-result-first" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].tipoFonte`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)"
                }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
          <v-col :align-self="style.alignSelf" cols="4">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].cpfCnpj`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Nome da Fonte Pagadora
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].nomeFontePagadora`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Ocupação
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].ocupacao`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Admissão
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].dataAdmissao`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{ `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}`
              }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?
                  `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` : "(VAZIO)"
              }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?
                  `${selectedField.originalValue.substring(8)}/${selectedField.originalValue.substring(5, 7)}/${selectedField.originalValue.substring(0, 4)}` : "(VAZIO)"
              }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Característica da Renda
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].caracteristica`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Bruta
          </v-col>
          <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="3">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].rendaBruta`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="2">
            Líquida
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="3">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].rendaLiquida`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Comprovante de Renda
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].documentoComprovante`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Data da Comprovação de Renda
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].dataComprovante`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row class="form-row-result resumo-list margin-lastIncome" no-gutters>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
            Valor do Imposto de Renda
          </v-col>
          <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
            <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
              v-if='selectedField.field === `rendasFormais[${doc}].impostoRetido`'>
              <span
                v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                    selectedField.currentValue }}</span>
              <div v-else>
                <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
                &#x27A4
                <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    
    

    <!-- RENDAS NÃO COMPROVADAS / INFORMAIS -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Rendas não Comprovadas / Informais</div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Atividade
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
            v-if='selectedField.field === `rendasInformais[true_FATURA CARTÃO, EXTRATO C/C].atividade`'>
            <span
              v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                  selectedField.currentValue }}</span>
            <div v-else>
              <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
              &#x27A4
              <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Data Início
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
            v-if='selectedField.field === `rendasInformais[true_FATURA CARTÃO, EXTRATO C/C].dataInicio`'>
            <span
              v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                  `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` }}</span>
            <div v-else>
              <span style="color:red">{{ selectedField.currentValue !== "" ?
                `${selectedField.currentValue.substring(8)}/${selectedField.currentValue.substring(5, 7)}/${selectedField.currentValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
              &#x27A4
              <span style="color:green">{{ selectedField.originalValue !== "" ?
                `${selectedField.originalValue.substring(8)}/${selectedField.originalValue.substring(5, 7)}/${selectedField.originalValue.substring(0, 4)}` : "(VAZIO)"
            }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Renda Liquida
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
            v-if='selectedField.field === `rendasInformais[true_FATURA CARTÃO, EXTRATO C/C].rendaLiquidaMensal`'>
            <span
              v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                  selectedField.currentValue }}</span>
            <div v-else>
              <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
              &#x27A4
              <span style="color:green">{{ selectedField.originalValue !== "" ?  selectedField.originalValue : "(VAZIO)" }}</span>
            </div>
          </div>
        </v-col>
      </v-row>

    <!-- PATRIMONIO CONSOLIDADO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Patrimônio Consolidado</div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Valor do Patrimônio Consolidado do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "patrimonioConsolidado.valorPatrimonio"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
        </div>
      </v-col>
    </v-row>

    <!-- AGÊNCIA DE RELACIONAMENTO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Agência de Relacionamento (Definida pelo Cliente)</div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        UF
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "agenciaRelacionamento.uf"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Município
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "agenciaRelacionamento.municipio"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Código e Nome das Agências
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        <div :key="selectedField.id" v-for="(selectedField, index) in cliente"
          v-if='selectedField.field === "agenciaRelacionamento.codigoNomeAgencia"'>
          <span
            v-if='selectedField.correct === true || selectedField.overwritten === true || selectedField.ignored === true'>{{
                selectedField.currentValue
            }}</span>
          <div v-else>
            <span style="color:red">{{ selectedField.currentValue !== "" ?  selectedField.currentValue : "(VAZIO)" }}</span>
            &#x27A4
            <span style="color:green">{{ selectedField.originalValue }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- AUTORIZACAO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-row-result-no-border text-justify texto-autorizacao">
        <div>
          Autorizo, nos termos da Resolução CMN n 3.658, de 17.12.2008, a CAIXA a consultar as informações consolidadas
          relativas a minha pessoa, constantes no Sistema de Informações de Créditos (SCR) do BACEN. Autorizo, ainda, a
          CAIXA a fornecer informações sobre as operações de crédito com ela realizadas, no sentido de compor o cadastro
          do já citado Sistema. Os presentes dados são verdadeiros e visam facilitar os processos de negociação e
          transações comerciais, pela antecipação de informações a meu respeito. Autorizo o arquivamento dos meus dados
          pessoais e de idoneidade na SERASA - Centralização de Serviços dos Bancos S/A, que poderá deles se utilizar,
          respeitadas as disposições legais em vigor.
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-12" no-gutters>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        LOCAL
      </v-col>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        DATA
      </v-col>
    </v-row>

    <v-row class="mt-12" no-gutters>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        Assinatura do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        Assinatura do Correspondente
      </v-col>
    </v-row>

    <v-row class="mt-12" no-gutters>
      <v-col class="form-row-result-no-border text-center">
        <div><b>SAC CAIXA</b>: 0800 726 0101 (informações, reclamações, sugestões e elogios)</div>
        <div><b>Para pessoas com deficiência auditica ou de fala</b>: 0800 726 2492</div>
        <div><b>Ouvidoria</b>: 0800 725 7474</div>
        <div>
          <b>caixa.gov.br</b>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8" no-gutters>
      <v-col class="text-right">
        <a @click="print" class="btn btn-azul" id="btn-imprimir">Imprimir Formulário</a>
      </v-col>
    </v-row>
    <v-row class="mt-6" no-gutters>
      <v-col class="text-right">
        <a @click="toAvaliacaoRisco" class="btn btn-azul" id="btn-avalicacao-risco"
          v-if="showAvaliacaoRiscoLink">Avaliação de Risco</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dossie from '../../store/modules/dossie';

// noinspection ES6CheckImport
export default {
  name: "ClienteResumo",
  props: ["cliente", "dossie", "toAvaliacaoRisco", "showAvaliacaoRiscoLink"],

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    isPassaporteVisible() {
      for (var field in this.cliente) {
        if (this.cliente[field] !== undefined && this.cliente[field].field === "tipoDocumento.value") {
          if (this.cliente[field].originalValue === "PASSAPORTE") {
            return true
          }
          else {
            return false
          }
        }
      }
    },
    isCnhVisible() {
      for (var field in this.cliente) {
        if (this.cliente[field] !== undefined && this.cliente[field].field === "tipoDocumento.value") {
          if (this.cliente[field].originalValue === "CNH - CARTEIRA NACIONAL DE HABILITACAO") {
            return true
          }
          else {
            return false
          }
        }
      }
    },
    comprovanteDeRendaInformal() {
      return documentoComprovacao => {
        const result = [];
        if (documentoComprovacao && documentoComprovacao.value === "SIM") {
          Object.keys(documentoComprovacao)
            .filter(key => key !== "value")
            .forEach(key => {
              if (documentoComprovacao[key].selected) {
                result.push(documentoComprovacao[key].text);
              }
            });
        } else {
          result.push("NÃO");
        }
        return result;
      };
    },
    isValidDate() {
      var month = false
      var year = false
      for (var field in this.cliente) {
        if (this.cliente[field] !== undefined && this.cliente[field].field === "endereco.comprovanteResidenciaMes") {
          if (this.cliente[field].correct === true || this.cliente[field].overwritten === true || this.cliente[field].ignored === true) {
            month = true
          }
        }
        if (this.cliente[field] !== undefined && this.cliente[field].field === "endereco.comprovanteResidenciaAno") {
          if (this.cliente[field].correct === true || this.cliente[field].overwritten === true || this.cliente[field].ignored === true) {
            year = true
          }
        }
      }
      return month === true && year === true
    },
    isFirstCnhVisible() {
      for (var field in this.cliente) {
        if (this.cliente[field] !== undefined && this.cliente[field].field === "tipoDocumento.dataPrimeiraHabilitacao") {
          if ((this.cliente[field].originalValue !== undefined && this.cliente[field].originalValue !== "" && this.cliente[field].originalValue !== null) || (this.cliente[field].currentValue !== undefined && this.cliente[field].currentValue !== "" && this.cliente[field].currentValue !== null)) {
            return true
          }
          else {
            return false
          }
        }
      }
    },
    isValidPhoneNumber() {
      var ddd = false
      var phone = false
      for (var field in this.cliente) {
        if (this.cliente[field] !== undefined && this.cliente[field].field === "telefones.contato.ddd") {
          if (this.cliente[field].correct === true || this.cliente[field].overwritten === true || this.cliente[field].ignored === true) {
            ddd = true
          }
        }
        if (this.cliente[field] !== undefined && this.cliente[field].field === "telefones.contato.telefone") {
          if (this.cliente[field].correct === true || this.cliente[field].overwritten === true || this.cliente[field].ignored === true) {
            phone = true
          }
        }
      }
      return ddd === true && phone === true
    }
  },
  methods: {
    listItemMargin(curIndex, list) {
      return curIndex >= list.length ? "" : "mb-2";
    },
    print() {
      window.print();
    },
    isDocumentoIdentificacaoVisible(resposta) {
      for (var field in resposta) {
        if (resposta[field] !== undefined && resposta[field].field === "tipoDocumento.value") {
          if (resposta[field].originalValue === "DOCUMENTO DE IDENTIFICACAO" || resposta[field].currentValue === "DOCUMENTO DE IDENTIFICACAO") {
            return true
          }
          else {
            return false
          }
        }
      }
    },
    isCaracteristicaNotSemRendaResult(index) {
      var cpf = this.dossie[index]
      var correctField = `rendasFormais[${cpf}].caracteristica`
      for (var field in this.cliente) {
        if (this.cliente[field] !== undefined && this.cliente[field].field === correctField) {
          if (this.cliente[field].originalValue !== "SEM RENDA") {
            return true
          }
          else {
            return false
          }
        }
      }
    },
  }
};
</script>

<style scoped src="./caixaqui-style.css">

</style>
<style scoped>
.texto-autorizacao {
  font-weight: bold;
  font-style: italic;
}

.assinatura-border {
  border-top: 1px solid #708090;
}

.resumo-list {
  background-color: #f0f0f0;
}

.margin-lastIncome{
  margin-bottom: 15px;
}

#btn-avalicacao-risco,
#btn-imprimir {
  padding: 3px 12px 3px 12px;
}
</style>
