<template>
  <v-container>
    <div class="d-flex justify-center mt-12">
      <v-form class="form-size">
        <v-file-input
          :error-messages="fileErrors"
          label="Arquivo de Dossiê"
          prepend-icon="fa-file-excel"
          accept=".xls,.xlsx,.csv"
          required
          outlined
          counter
          class="d-flex"
          v-model="file"
          :show-size="1000"
        ></v-file-input>

        <v-btn @click="upload" class="mr-4">Enviar</v-btn>
        <v-btn @click="cancelar" class="mr-4">Cancelar</v-btn>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import ROUTERS from "../../routes/router-constants";

export default {
  mixins: [validationMixin],
  name: "DossieUpload",
  data: () => ({
    file: undefined
  }),
  validations: {
    file: { required }
  },
  computed: {
    fileErrors() {
      const errors = [];
      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.required && errors.push("Arquivo de Dossiê é obrigatório.");
      return errors;
    }
  },
  methods: {
    ...mapActions("dossie", ["uploadDossies"]),
    upload() {
      const element = this;
      element.$v.$touch();
      if (!element.$v.$invalid) {
        element
          .uploadDossies(element.file)
          .then(result => {
            element.file = undefined;
            element.$v.$reset();

            if (result.data.status === "WARNING") {
              let htmlWarningResult = `
              <p>CPFs foram importados com sucesso: ${result.data.data.successes.length}</p>
              <br/>
              <p>Alguns CPFs informados não foram importados com sucesso:</p>><br/>
            `;

              result.data.data.errors.forEach(error => {
                htmlWarningResult += `<p>${error.cpf} : ${error.message}</p>`;
              });

              element.$alert.warn(htmlWarningResult);
              element.$router.push({ name: ROUTERS.dossies.name });
            } else if (result.data.status === "ERROR") {
              let htmlErrorResult = `<p>Os CPFs informados não foram importados com sucesso:</p><br/>`;

              result.data.data.errors.forEach(error => {
                htmlErrorResult += `<p>${error.cpf} : ${error.message}</p>`;
              });

              element.$alert.error(htmlErrorResult);
            } else {
              element.$alert.notifySuccess("Importação de Dossiês efetuada com sucesso!");
              element.$router.push({ name: ROUTERS.dossies.name });
            }
          })
          .catch(function() {
            element.file = undefined;
            element.$v.$reset();
          });
      }
    },
    cancelar() {
      this.$v.$reset();
      this.$router.push({ name: ROUTERS.dossies.name });
    },
    getRoute: function() {
      return { name: ROUTERS.dossiesUpload.name };
    }
  }
};
</script>

<style scoped>
.form-size {
  width: 800px;
}

.input-size {
  width: 500px;
}
</style>
