var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "d-flex" }, [
    _c("div", { staticClass: "mx-auto" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-center mt-3" },
        [
          _c(
            "v-card",
            { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
            [
              _c("v-data-table", {
                staticClass: "data-table elevation-0",
                attrs: {
                  "footer-props": _vm.footer,
                  headers: _vm.headers,
                  items: _vm.treinamentos,
                  loading: _vm.loading,
                  search: _vm.search,
                  "item-key": "id"
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function() {
                      return [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "mb-3",
                            attrs: { color: "white", flat: "" }
                          },
                          [
                            _c("v-toolbar-title", [_vm._v("CORREÇÃO")]),
                            _c("v-spacer"),
                            _c("v-text-field", {
                              attrs: {
                                "append-icon": "fa-search",
                                "hide-details": "",
                                label: "Pesquisar",
                                "single-line": ""
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            }),
                            _c("v-spacer"),
                            _c(
                              "v-radio-group",
                              {
                                staticClass: "mt-11",
                                on: { change: _vm.statusFilter },
                                model: {
                                  value: _vm.selectedStatus,
                                  callback: function($$v) {
                                    _vm.selectedStatus = $$v
                                  },
                                  expression: "selectedStatus"
                                }
                              },
                              _vm._l(_vm.statuses, function(item) {
                                return _c("v-radio", {
                                  key: item.value,
                                  attrs: { label: item.text, value: item.value }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.dossie.nome",
                    fn: function({ item }) {
                      return [
                        _c("span", [_vm._v(_vm._s(item.dossie.nome))]),
                        _vm.isCorrigido(item)
                          ? _c(
                              "v-tooltip",
                              {
                                staticClass: "ml-3",
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: "green darken-2",
                                                  dark: ""
                                                }
                                              },
                                              on
                                            ),
                                            [_vm._v("fa-check")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [_c("span", [_vm._v(_vm._s(item.status))])]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item.completedAt",
                    fn: function({ item }) {
                      return [
                        _c("span", { staticClass: "table-date-info" }, [
                          _vm._v(_vm._s(_vm.formatDate(item.completedAt)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.nota",
                    fn: function({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _vm.showGrade(item)
                                        ? _c(
                                            "v-progress-circular",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  rotate: -90,
                                                  value: _vm.grade(item),
                                                  color: "primary"
                                                }
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.grade(item)) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.gradeRaw(item)) + "%")
                            ])
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.action",
                    fn: function({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: { medium: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.corrigir(item)
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("fa-edit")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Corrigir")])]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }