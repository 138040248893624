import _ from "lodash";

const telasFieldAR = [
  { nome: "Dados do Resposta Assino", fieldPrefix: "cpfProponente1" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "cpfProponente2" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "dataAvaliação" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "dataVencimento" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "enquadramento" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "fgts" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "indexador" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "nomeProponente1" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "nomeProponente2" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "observacoesPendencias" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "possuiDireitoRedutorTaxa" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "possuiFGTS" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "prazoMeses" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "valorTotalProposta" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "rendaFamiliar" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "sistemaAmortização" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "situacaoAnalise" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "subsidio" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "valorFinanciamento" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "valorIMovel" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "valorPrestacao" },
  { nome: "Dados do Resposta Assino", fieldPrefix: "valorTaxaJuros" }
];

const telaOrder = _.chain(telasFieldAR)
  .map(t => t.nome)
  .uniq()
  .value();

function getTelaOrder(tela) {
  return _.indexOf(telaOrder, tela);
}

function getFiledOrder(record) {
  return _.indexOf(telasFieldAR, record);
}

function getTelaFromFieldPrefix(field) {
  const record = _.find(telasFieldAR, o => _.startsWith(field, o.fieldPrefix));
  if (_.isNil(record)) {
    return { nome: "", fieldPrefix: "", telaOrder: 0, fieldOrder: 0 };
  }
  return { ...record, telaOrder: getTelaOrder(record.nome), fieldOrder: getFiledOrder(record) };
}

export { getTelaFromFieldPrefix as getTelaRespostaAssino };
