<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main" id="resumo">
    <!-- TOMADOR-->
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Dados do Tomador</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CPF
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.cpf }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ avaliacaoRisco.nomeCompleto }}
      </v-col>
    </v-row>

    <!-- PRODUTOS -->
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">Produtos</v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="creditoImobiliario"
                name="creditoImobiliario"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.creditoImobiliario"
              />
            </div>
            <label for="creditoImobiliario">1 - Crédito Imobiliário</label>
          </div>

          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="consignacao"
                name="consignacao"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.consignacao"
              />
            </div>
            <label for="consignacao">2 - Consignação</label>
          </div>

          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="cartaoCredito"
                name="cartaoCredito"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.cartaoCredito"
              />
            </div>
            <label for="cartaoCredito">3 - Cartão de Crédito</label>
          </div>

          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="contaChequeAzul"
                name="contaChequeAzul"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.contaChequeAzul"
              />
            </div>
            <label for="contaChequeAzul">4 - Conta com Cheque Azul(CROT)</label>
          </div>

          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="consigPortabilidade"
                name="consigPortabilidade"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.consigPortabilidade"
              />
            </div>
            <label for="consigPortabilidade">9 - Consignação Portabilidade</label>
          </div>

          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="chequeAzulIsolado"
                name="chequeAzulIsolado"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.chequeAzulIsolado"
              />
            </div>
            <label for="chequeAzulIsolado">10 - Conta com Cheque Azul(CROT)</label>
          </div>

          <div class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center">
              <input
                disabled
                id="chequeAzulIsolado"
                name="chequeAzulIsolado"
                type="checkbox"
                v-model="avaliacaoRisco.produtos.chequeAzulIsolado"
              />
            </div>
            <label for="chequeAzulIsolado">11 - Conta com Cheque Azul(CROT)</label>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- PARITICIPANTES-->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Paricipantes</div>
      </v-col>
    </v-row>
    <div :key="item.uid" v-for="(item, index) in avaliacaoRisco.participantes">
      <v-row class="form-row-result resumo-list form-row-result-first" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          CPF
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          {{ item.cpf }}
        </v-col>
      </v-row>

      <v-row
        :class="['form-row-result', 'resumo-list', listItemMargin(index, avaliacaoRisco.participantes)]"
        no-gutters
      >
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Nome
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
          {{ item.nome }}
        </v-col>
      </v-row>
    </div>

    <!-- INFORMAÇÕES DA OPERAÇÃO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Informações da Operação</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Origem do Recurso
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.valor }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Modalidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.modalidade }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Você possui convênio?
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.convenio }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Prazo Total (em meses)
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.prazoTotal }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Valor do Imóvel -R$
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.valorImovel }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Prestação necessária para o financiamento - R$
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.prestNecessaria }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Valor do Financiamento - R$
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.valorFinanciamento }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Garantia
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.garantia }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Portabilidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.portabilidade }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Sistema de Amortização
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.origemRecurso.sistemaAmortizacao }}
      </v-col>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Indexador
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          {{ avaliacaoRisco.origemRecurso.indexador }}
        </v-col>
      </v-row>
    </v-row>

    <!--AUTOFINANCIAMENTO-->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Autofinanciamento</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CNPJ Construtora
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ avaliacaoRisco.autoFinanciamento.cnpjConstrutora }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Prestações
      </v-col>
      <v-col :align-self="style.alignSelf" :cols="style.colRight" class="form-col-right align-center pa-0">
        <v-row class="form-row no-border" no-gutters>
          <v-col
            :align-self="style.alignSelf"
            :class="[...style.colLeftClasses, 'form-result-label', 'form-col-top-custom']"
            cols="2"
          >
            Prestação
          </v-col>
          <v-col
            :align-self="style.alignSelf"
            :class="[...style.colLeftClasses, 'form-result-label', 'form-col-top-custom']"
            cols="3"
          >
            Data de Vencimento
          </v-col>
          <v-col
            :align-self="style.alignSelf"
            :class="[...style.colLeftClasses, 'form-result-label', 'form-col-top-custom']"
            cols="3"
          >
            Data de Pagamento
          </v-col>
          <v-col
            :align-self="style.alignSelf"
            :class="[...style.colLeftClasses, 'form-result-label', 'no-border-right', 'form-col-top-custom']"
            cols="4"
          >
            Valor da Prestação
          </v-col>
        </v-row>
        <v-row
          :class="['form-row', 'no-border', item.numero === 6 ? 'no-border-bottom' : '']"
          :key="item.numero"
          no-gutters
          v-for="item in prestacoes"
        >
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="2">
            {{ item.numero }}
          </v-col>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="3">
            {{ item.dataVencimento }}
          </v-col>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="3">
            {{ item.dataPagamento }}
          </v-col>
          <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'no-border-right']" cols="4">
            {{ item.valorPrestacao }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport

export default {
  name: "AvaliacaoRiscoResumo",
  props: ["avaliacaoRisco"],

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    prestacoes() {
      return this.avaliacaoRisco.autoFinanciamento.prestacoes;
    }
  },
  methods: {
    listItemMargin(curIndex, list) {
      return curIndex >= list.length ? "" : "mb-2";
    }
  }
};
</script>

<style scoped src="./caixaqui-style.css"></style>
<style scoped>
.resumo-list {
  background-color: #f0f0f0;
}

.no-border-right {
  border-right: none;
}

.no-border-bottom {
  border-bottom: none;
}

.no-border {
  border-top: none;
  border-right: none;
  border-left: none;
}

.form-col-top-custom {
  border-top: none;
}
</style>
