var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-6", attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c("img", {
              staticClass: "rounded-circle img-fluid profile-picture",
              attrs: { src: _vm.profile.picture, alt: "User's profile picture" }
            })
          ]),
          _c("v-col", [
            _c("div", { staticClass: "mt-5" }, [
              _c("h2", [_vm._v(_vm._s(_vm.profile.name))]),
              _c("p", { staticClass: "lead text-muted" }, [
                _vm._v(_vm._s(_vm.profile.email))
              ])
            ])
          ])
        ],
        1
      ),
      _c("v-row", [
        _c(
          "pre",
          {
            directives: [{ name: "highlight", rawName: "v-highlight" }],
            staticClass: "rounded full-width"
          },
          [
            _c("code", { staticClass: "json" }, [
              _vm._v(_vm._s(JSON.stringify(_vm.profile, null, 2)))
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }