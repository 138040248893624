var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main", attrs: { id: "resumo" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados de Identificação")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Protocolo\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      000000000\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Código do Correspondente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      000000000\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Declaração de Propósitos")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-center" },
                  [
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.movimentacaoContaDepositoPoupanca"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "movimentaContaDepositoPoupanca",
                              name: "movimentaContaDepositoPoupanca",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(fields.originalValue)
                                ? _vm._i(fields.originalValue, null) > -1
                                : fields.originalValue
                            },
                            on: {
                              change: function($event) {
                                var $$a = fields.originalValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(fields, "originalValue", $$c)
                                }
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "movimentaContaDepositoPoupanca" }
                      },
                      [
                        _vm._v(
                          "Movimentação de Conta de\n            Depósito/Poupança"
                        )
                      ]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-center" },
                  [
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.emprestimosFinanciamentos"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "emprestimosFinanciamentos",
                              name: "emprestimosFinanciamentos",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(fields.originalValue)
                                ? _vm._i(fields.originalValue, null) > -1
                                : fields.originalValue
                            },
                            on: {
                              change: function($event) {
                                var $$a = fields.originalValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(fields, "originalValue", $$c)
                                }
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "emprestimosFinanciamentos" }
                      },
                      [_vm._v("Empréstimos/Financiamentos")]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row align-center justify-center",
                    attrs: { id: "habitacional" }
                  },
                  [
                    _c("label", { staticClass: "form-checkbox-label" }, [
                      _vm._v("Financiamento Habitacional")
                    ]),
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.financiamentoHabitacional"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "habitacionalSIM",
                              name: "habitacional",
                              type: "radio"
                            },
                            domProps: {
                              value: true,
                              checked: _vm._q(fields.originalValue, true)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(fields, "originalValue", true)
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "habitacionalSIM" }
                      },
                      [_vm._v("SIM")]
                    ),
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.financiamentoHabitacional"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "habitacionalNAO",
                              name: "habitacional",
                              type: "radio"
                            },
                            domProps: {
                              value: false,
                              checked: _vm._q(fields.originalValue, false)
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(fields, "originalValue", false)
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "habitacionalNAO" }
                      },
                      [_vm._v("NÃO")]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-center" },
                  [
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.investimentos"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "investimentos",
                              name: "investimentos",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(fields.originalValue)
                                ? _vm._i(fields.originalValue, null) > -1
                                : fields.originalValue
                            },
                            on: {
                              change: function($event) {
                                var $$a = fields.originalValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(fields, "originalValue", $$c)
                                }
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "investimentos" }
                      },
                      [_vm._v("Investimentos")]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-center" },
                  [
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.cartaoCredito"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "cartaoCredito",
                              name: "cartaoCredito",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(fields.originalValue)
                                ? _vm._i(fields.originalValue, null) > -1
                                : fields.originalValue
                            },
                            on: {
                              change: function($event) {
                                var $$a = fields.originalValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(fields, "originalValue", $$c)
                                }
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "cartaoCredito" }
                      },
                      [_vm._v("Cartão de Crédito")]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-center" },
                  [
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.seguroPrevidenciaCapitalizacaoConsorcios"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "seguroPrevidenciaCapitalizacaoConsorcios",
                              name: "seguroPrevidenciaCapitalizacaoConsorcios",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(fields.originalValue)
                                ? _vm._i(fields.originalValue, null) > -1
                                : fields.originalValue
                            },
                            on: {
                              change: function($event) {
                                var $$a = fields.originalValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(fields, "originalValue", $$c)
                                }
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: {
                          for: "seguroPrevidenciaCapitalizacaoConsorcios"
                        }
                      },
                      [
                        _vm._v(
                          "Seguros/Previdência\n            Privada/Capitalização/Consórcios"
                        )
                      ]
                    )
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-row align-center" },
                  [
                    _vm._l(_vm.cliente, function(fields, index) {
                      return fields.field ===
                        "declaracaoPropositos.operacoesInternacionaisCambio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: fields.originalValue,
                                expression: "fields.originalValue"
                              }
                            ],
                            attrs: {
                              disabled: "",
                              id: "operacoesInternacionaisCambio",
                              name: "operacoesInternacionaisCambio",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(fields.originalValue)
                                ? _vm._i(fields.originalValue, null) > -1
                                : fields.originalValue
                            },
                            on: {
                              change: function($event) {
                                var $$a = fields.originalValue,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        fields,
                                        "originalValue",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(fields, "originalValue", $$c)
                                }
                              }
                            }
                          })
                        : _vm._e()
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "operacoesInternacionaisCambio" }
                      },
                      [_vm._v("Operações\n            Internacionais/Câmbio")]
                    )
                  ],
                  2
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados Cadastrais")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CPF do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "cpf"
                ? _c("div", { key: selectedField.id }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(selectedField.currentValue) +
                        "\n      "
                    )
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome Completo do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "nomeCompleto"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(selectedField.currentValue))
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome Reduzido do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "nomeReduzido"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Data de Nascimento\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "form-col-left"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "dataNascimento"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(selectedField.currentValue.substring(8)) +
                              "/" +
                              _vm._s(
                                selectedField.currentValue.substring(5, 7)
                              ) +
                              "/" +
                              _vm._s(selectedField.currentValue.substring(0, 4))
                          )
                        ])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? `${selectedField.currentValue.substring(
                                      8
                                    )}/${selectedField.currentValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.currentValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              _vm._s(selectedField.originalValue.substring(8)) +
                                "/" +
                                _vm._s(
                                  selectedField.originalValue.substring(5, 7)
                                ) +
                                "/" +
                                _vm._s(
                                  selectedField.originalValue.substring(0, 4)
                                )
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            [_vm._v("\n      Sexo\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "sexo"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nacionalidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "nacionalidade"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Naturalidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._l(_vm.cliente, function(selectedField, index) {
                return selectedField.field === "naturalidade.municipio"
                  ? _c("div", { key: selectedField.id }, [
                      selectedField.correct === true ||
                      selectedField.overwritten === true ||
                      selectedField.ignored === true
                        ? _c("span", [
                            _vm._v(_vm._s(selectedField.currentValue) + "/ ")
                          ])
                        : _c("div", [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? selectedField.currentValue
                                    : "(VAZIO)"
                                )
                              )
                            ]),
                            _vm._v("\n          ➤\n          "),
                            _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(selectedField.originalValue))
                            ])
                          ])
                    ])
                  : _vm._e()
              }),
              _vm._l(_vm.cliente, function(selectedField, index) {
                return selectedField.field === "naturalidade.uf"
                  ? _c("div", { key: selectedField.id }, [
                      selectedField.correct === true ||
                      selectedField.overwritten === true ||
                      selectedField.ignored === true
                        ? _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(selectedField.currentValue)
                            )
                          ])
                        : _c("div", [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? selectedField.currentValue
                                    : "(VAZIO)"
                                )
                              )
                            ]),
                            _vm._v("\n          ➤\n          "),
                            _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(selectedField.originalValue))
                            ])
                          ])
                    ])
                  : _vm._e()
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome do Pai\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "nomePai"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome do Mãe\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "nomeMae"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Grau de Instrução\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "grauInstrucao"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      PIS/NIS\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "pisNis"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Documento de Identidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "tipoDocumento.value"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _vm.isDocumentoIdentificacaoVisible(_vm.cliente)
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      Subtipo do Documento\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                _vm._l(_vm.cliente, function(selectedField, index) {
                  return selectedField.field ===
                    "tipoDocumento.documentoIdentificacao.subTipo"
                    ? _c("div", { key: selectedField.id }, [
                        selectedField.correct === true ||
                        selectedField.overwritten === true ||
                        selectedField.ignored === true
                          ? _c("span", [
                              _vm._v(_vm._s(selectedField.currentValue))
                            ])
                          : _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(
                                    selectedField.currentValue !== ""
                                      ? selectedField.currentValue
                                      : "(VAZIO)"
                                  )
                                )
                              ]),
                              _vm._v("\n          ➤\n          "),
                              _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v(_vm._s(selectedField.originalValue))
                              ])
                            ])
                      ])
                    : _vm._e()
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [
              _vm.isPassaporteVisible
                ? _c("span", [_vm._v("Número do Passaporte")])
                : _vm._e(),
              _vm.isCnhVisible
                ? _c("span", [
                    _vm._v("Número da Carteira Nacional de Habilitação")
                  ])
                : _c("span", [_vm._v("Número da Identidade")])
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "tipoDocumento.numero"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Órgão Emissor\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "tipoDocumento.orgaoEmissor"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      UF\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "tipoDocumento.uf"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _vm.isPassaporteVisible
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      País de origem\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                _vm._l(_vm.cliente, function(selectedField, index) {
                  return selectedField.field ===
                    "tipoDocumento.passaporte.paisOrigem"
                    ? _c("div", { key: selectedField.id }, [
                        selectedField.correct === true ||
                        selectedField.overwritten === true ||
                        selectedField.ignored === true
                          ? _c("span", [
                              _vm._v(_vm._s(selectedField.currentValue))
                            ])
                          : _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(
                                    selectedField.currentValue !== ""
                                      ? selectedField.currentValue
                                      : "(VAZIO)"
                                  )
                                )
                              ]),
                              _vm._v("\n          ➤\n          "),
                              _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v(_vm._s(selectedField.originalValue))
                              ])
                            ])
                      ])
                    : _vm._e()
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isFirstCnhVisible
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      Data 1ª Habilitação\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                _vm._l(_vm.cliente, function(selectedField, index) {
                  return selectedField.field ===
                    "tipoDocumento.dataPrimeiraHabilitacao"
                    ? _c("div", { key: selectedField.id }, [
                        selectedField.correct === true ||
                        selectedField.overwritten === true ||
                        selectedField.ignored === true
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    selectedField.currentValue.substring(8)
                                  ) +
                                  "/" +
                                  _vm._s(
                                    selectedField.currentValue.substring(5, 7)
                                  ) +
                                  "/" +
                                  _vm._s(
                                    selectedField.currentValue.substring(0, 4)
                                  )
                              )
                            ])
                          : _c("div", [
                              _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(
                                    selectedField.currentValue !== ""
                                      ? `${selectedField.currentValue.substring(
                                          8
                                        )}/${selectedField.currentValue.substring(
                                          5,
                                          7
                                        )}/${selectedField.currentValue.substring(
                                          0,
                                          4
                                        )}`
                                      : "(VAZIO)"
                                  )
                                )
                              ]),
                              _vm._v("\n          ➤\n          "),
                              _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v(
                                  _vm._s(
                                    selectedField.originalValue !== ""
                                      ? `${selectedField.originalValue.substring(
                                          8
                                        )}/${selectedField.originalValue.substring(
                                          5,
                                          7
                                        )}/${selectedField.originalValue.substring(
                                          0,
                                          4
                                        )}`
                                      : "(VAZIO)"
                                  )
                                )
                              ])
                            ])
                      ])
                    : _vm._e()
                }),
                0
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Data de Emissão\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "form-col-left"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "tipoDocumento.dataEmissao"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(selectedField.currentValue.substring(8)) +
                              "/" +
                              _vm._s(
                                selectedField.currentValue.substring(5, 7)
                              ) +
                              "/" +
                              _vm._s(selectedField.currentValue.substring(0, 4))
                          )
                        ])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? `${selectedField.currentValue.substring(
                                      8
                                    )}/${selectedField.currentValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.currentValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.originalValue !== ""
                                  ? `${selectedField.originalValue.substring(
                                      8
                                    )}/${selectedField.originalValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.originalValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          ),
          _c(
            "v-col",
            {
              class: [
                ..._vm.style.colLeftClasses,
                "text-no-wrap",
                "form-fim-val-label"
              ],
              attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
            },
            [_vm._v("\n      Data Fim Validade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "tipoDocumento.dataFimValidade"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              selectedField.currentValue !== ""
                                ? `${selectedField.currentValue.substring(
                                    8
                                  )}/${selectedField.currentValue.substring(
                                    5,
                                    7
                                  )}/${selectedField.currentValue.substring(
                                    0,
                                    4
                                  )}`
                                : ""
                            )
                          )
                        ])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? `${selectedField.currentValue.substring(
                                      8
                                    )}/${selectedField.currentValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.currentValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.originalValue !== ""
                                  ? `${selectedField.originalValue.substring(
                                      8
                                    )}/${selectedField.originalValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.originalValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Estado Civil\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "estadoCivil"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Ocupação\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "ocupacao"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados do Conjuge")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CPF\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "conjuge.cpf"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(selectedField.currentValue))
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "conjuge.nome"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(selectedField.currentValue))
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Endereço")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CEP\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.cep"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Logradouro\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.logradouro"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c(
                          "div",
                          [
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field === "endereco.tipoLogradouro"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          field.currentValue !== ""
                                            ? field.currentValue + " "
                                            : "(VAZIO)"
                                        )
                                      )
                                    ]
                                  )
                                : _vm._e()
                            }),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? selectedField.currentValue
                                    : ""
                                ) + " "
                              )
                            ]),
                            _vm._v("\n          ➤\n          "),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field === "endereco.tipoLogradouro"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "green" } },
                                    [_vm._v(_vm._s(field.originalValue + " "))]
                                  )
                                : _vm._e()
                            }),
                            _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(selectedField.originalValue))
                            ])
                          ],
                          2
                        )
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Complemento\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [
                ..._vm.style.colRightClasses,
                "form-col-left",
                "text-uppercase"
              ],
              attrs: { "align-self": _vm.style.alignSelf, cols: "5" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.complemento"
                ? _c("div", { key: selectedField.id }, [
                    _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                  ])
                : _vm._e()
            }),
            0
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            [_vm._v("\n      Número\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "1" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.numero"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Município\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [
                ..._vm.style.colRightClasses,
                "form-col-left",
                "text-uppercase"
              ],
              attrs: { "align-self": _vm.style.alignSelf, cols: "5" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.cidade"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            [_vm._v("\n      Estado\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "1" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.uf"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Bairro\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.bairro"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Imóvel\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.tipoImovel"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Ocupação do Imóvel\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.ocupacaoImovel"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Comprovante de Residência\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "endereco.comprovanteResidenciaMes"
                ? _c("div", { key: selectedField.id }, [
                    _vm.isValidDate
                      ? _c(
                          "div",
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? `${selectedField.currentValue} /`
                                    : "(VAZIO)"
                                )
                              )
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "endereco.comprovanteResidenciaAno"
                                ? _c("span", [
                                    _vm._v(_vm._s(field.currentValue))
                                  ])
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      : _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? `${selectedField.currentValue} /`
                                    : "(VAZIO)"
                                )
                              )
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "endereco.comprovanteResidenciaAno"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v(_vm._s(field.currentValue))]
                                  )
                                : _vm._e()
                            }),
                            _vm._v("\n          ➤\n          "),
                            _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(_vm._s(selectedField.originalValue) + "/")
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "endereco.comprovanteResidenciaAno"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "green" } },
                                    [_vm._v(_vm._s(field.originalValue))]
                                  )
                                : _vm._e()
                            })
                          ],
                          2
                        )
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Telefones")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Telefone de Contato\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "telefones.contato.ddd"
                ? _c("div", { key: selectedField.id }, [
                    _vm.isValidPhoneNumber
                      ? _c(
                          "div",
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? `(${selectedField.currentValue})`
                                    : "(VAZIO)"
                                )
                              )
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "telefones.contato.telefone"
                                ? _c("span", [
                                    _vm._v(_vm._s(" " + field.currentValue))
                                  ])
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      : _c(
                          "div",
                          [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(
                                _vm._s(
                                  selectedField.currentValue !== ""
                                    ? `(${selectedField.currentValue})`
                                    : "(VAZIO)"
                                )
                              )
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "telefones.contato.telefone"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v(_vm._s(" " + field.currentValue))]
                                  )
                                : _vm._e()
                            }),
                            _vm._v("\n          ➤\n          "),
                            _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v(
                                "(" + _vm._s(selectedField.originalValue) + ")"
                              )
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "telefones.contato.telefone"
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "green" } },
                                    [_vm._v(_vm._s(" " + field.originalValue))]
                                  )
                                : _vm._e()
                            })
                          ],
                          2
                        )
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Telefone Celular\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "telefones.celular.ddd"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.currentValue !== ""
                      ? _c(
                          "div",
                          [
                            _c("span", [
                              _vm._v(
                                "(" + _vm._s(selectedField.currentValue) + ")"
                              )
                            ]),
                            _vm._l(_vm.cliente, function(field, index) {
                              return field.field ===
                                "telefones.celular.telefone"
                                ? _c("span", [
                                    _vm._v(_vm._s(" " + field.currentValue))
                                  ])
                                : _vm._e()
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Renda Formal")])
          ])
        ],
        1
      ),
      _vm._l(_vm.dossie, function(doc, index) {
        return _c("div", [
          _vm.isCaracteristicaNotSemRendaResult(index)
            ? _c(
                "div",
                [
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "form-row-result resumo-list form-row-result-first",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].tipoFonte`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          selectedField.currentValue !== ""
                                            ? selectedField.currentValue
                                            : "(VAZIO)"
                                        )
                                      )
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].cpfCnpj`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [_vm._v("\n          Nome da Fonte Pagadora\n        ")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].nomeFontePagadora`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [_vm._v("\n          Ocupação\n        ")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].ocupacao`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [_vm._v("\n          Admissão\n        ")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].dataAdmissao`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          `${selectedField.currentValue.substring(
                                            8
                                          )}/${selectedField.currentValue.substring(
                                            5,
                                            7
                                          )}/${selectedField.currentValue.substring(
                                            0,
                                            4
                                          )}`
                                        )
                                      )
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? `${selectedField.currentValue.substring(
                                                    8
                                                  )}/${selectedField.currentValue.substring(
                                                    5,
                                                    7
                                                  )}/${selectedField.currentValue.substring(
                                                    0,
                                                    4
                                                  )}`
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? `${selectedField.originalValue.substring(
                                                    8
                                                  )}/${selectedField.originalValue.substring(
                                                    5,
                                                    7
                                                  )}/${selectedField.originalValue.substring(
                                                    0,
                                                    4
                                                  )}`
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [
                          _vm._v(
                            "\n          Característica da Renda\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].caracteristica`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [_vm._v("\n          Bruta\n        ")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: [
                            ..._vm.style.colRightClasses,
                            "form-col-left"
                          ],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "3"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].rendaBruta`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "2"
                          }
                        },
                        [_vm._v("\n          Líquida\n        ")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "3"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].rendaLiquida`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [_vm._v("\n          Comprovante de Renda\n        ")]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].documentoComprovante`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "form-row-result resumo-list",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [
                          _vm._v(
                            "\n          Data da Comprovação de Renda\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].dataComprovante`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "form-row-result resumo-list margin-lastIncome",
                      attrs: { "no-gutters": "" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          class: [..._vm.style.colLeftClasses],
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "4"
                          }
                        },
                        [
                          _vm._v(
                            "\n          Valor do Imposto de Renda\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          class: _vm.style.colRightClasses,
                          attrs: {
                            "align-self": _vm.style.alignSelf,
                            cols: "8"
                          }
                        },
                        _vm._l(_vm.cliente, function(selectedField, index) {
                          return selectedField.field ===
                            `rendasFormais[${doc}].impostoRetido`
                            ? _c("div", { key: selectedField.id }, [
                                selectedField.correct === true ||
                                selectedField.overwritten === true ||
                                selectedField.ignored === true
                                  ? _c("span", [
                                      _vm._v(_vm._s(selectedField.currentValue))
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.currentValue !== ""
                                                ? selectedField.currentValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n              ➤\n              "
                                      ),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              selectedField.originalValue !== ""
                                                ? selectedField.originalValue
                                                : "(VAZIO)"
                                            )
                                          )
                                        ]
                                      )
                                    ])
                              ])
                            : _vm._e()
                        }),
                        0
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ])
      }),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Rendas não Comprovadas / Informais")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: [..._vm.style.colLeftClasses],
              attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
            },
            [_vm._v("\n        Atividade\n      ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field ===
                `rendasInformais[true_FATURA CARTÃO, EXTRATO C/C].atividade`
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n            ➤\n            "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.originalValue !== ""
                                  ? selectedField.originalValue
                                  : "(VAZIO)"
                              )
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: [..._vm.style.colLeftClasses],
              attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
            },
            [_vm._v("\n        Data Início\n      ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field ===
                `rendasInformais[true_FATURA CARTÃO, EXTRATO C/C].dataInicio`
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              `${selectedField.currentValue.substring(
                                8
                              )}/${selectedField.currentValue.substring(
                                5,
                                7
                              )}/${selectedField.currentValue.substring(0, 4)}`
                            )
                          )
                        ])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? `${selectedField.currentValue.substring(
                                      8
                                    )}/${selectedField.currentValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.currentValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n            ➤\n            "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.originalValue !== ""
                                  ? `${selectedField.originalValue.substring(
                                      8
                                    )}/${selectedField.originalValue.substring(
                                      5,
                                      7
                                    )}/${selectedField.originalValue.substring(
                                      0,
                                      4
                                    )}`
                                  : "(VAZIO)"
                              )
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: [..._vm.style.colLeftClasses],
              attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
            },
            [_vm._v("\n        Renda Liquida\n      ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field ===
                `rendasInformais[true_FATURA CARTÃO, EXTRATO C/C].rendaLiquidaMensal`
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n            ➤\n            "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.originalValue !== ""
                                  ? selectedField.originalValue
                                  : "(VAZIO)"
                              )
                            )
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Patrimônio Consolidado")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Valor do Patrimônio Consolidado do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field ===
                "patrimonioConsolidado.valorPatrimonio"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _vm._e()
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [
              _vm._v("Agência de Relacionamento (Definida pelo Cliente)")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      UF\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "agenciaRelacionamento.uf"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Município\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field === "agenciaRelacionamento.municipio"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Código e Nome das Agências\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            _vm._l(_vm.cliente, function(selectedField, index) {
              return selectedField.field ===
                "agenciaRelacionamento.codigoNomeAgencia"
                ? _c("div", { key: selectedField.id }, [
                    selectedField.correct === true ||
                    selectedField.overwritten === true ||
                    selectedField.ignored === true
                      ? _c("span", [_vm._v(_vm._s(selectedField.currentValue))])
                      : _c("div", [
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              _vm._s(
                                selectedField.currentValue !== ""
                                  ? selectedField.currentValue
                                  : "(VAZIO)"
                              )
                            )
                          ]),
                          _vm._v("\n          ➤\n          "),
                          _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(selectedField.originalValue))
                          ])
                        ])
                  ])
                : _vm._e()
            }),
            0
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass:
                "form-row-result-no-border text-justify texto-autorizacao"
            },
            [
              _c("div", [
                _vm._v(
                  "\n        Autorizo, nos termos da Resolução CMN n 3.658, de 17.12.2008, a CAIXA a consultar as informações consolidadas\n        relativas a minha pessoa, constantes no Sistema de Informações de Créditos (SCR) do BACEN. Autorizo, ainda, a\n        CAIXA a fornecer informações sobre as operações de crédito com ela realizadas, no sentido de compor o cadastro\n        do já citado Sistema. Os presentes dados são verdadeiros e visam facilitar os processos de negociação e\n        transações comerciais, pela antecipação de informações a meu respeito. Autorizo o arquivamento dos meus dados\n        pessoais e de idoneidade na SERASA - Centralização de Serviços dos Bancos S/A, que poderá deles se utilizar,\n        respeitadas as disposições legais em vigor.\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      LOCAL\n    ")]
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      DATA\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      Assinatura do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      Assinatura do Correspondente\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "form-row-result-no-border text-center" },
            [
              _c("div", [
                _c("b", [_vm._v("SAC CAIXA")]),
                _vm._v(
                  ": 0800 726 0101 (informações, reclamações, sugestões e elogios)"
                )
              ]),
              _c("div", [
                _c("b", [
                  _vm._v("Para pessoas com deficiência auditica ou de fala")
                ]),
                _vm._v(": 0800 726 2492")
              ]),
              _c("div", [
                _c("b", [_vm._v("Ouvidoria")]),
                _vm._v(": 0800 725 7474")
              ]),
              _c("div", [_c("b", [_vm._v("caixa.gov.br")])])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-8", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-azul",
                attrs: { id: "btn-imprimir" },
                on: { click: _vm.print }
              },
              [_vm._v("Imprimir Formulário")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-right" }, [
            _vm.showAvaliacaoRiscoLink
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-azul",
                    attrs: { id: "btn-avalicacao-risco" },
                    on: { click: _vm.toAvaliacaoRisco }
                  },
                  [_vm._v("Avaliação de Risco")]
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }