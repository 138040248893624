var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex justify-center mt-3" },
      [
        _c(
          "v-card",
          { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
          [
            _c("v-data-table", {
              staticClass: "data-table elevation-0",
              attrs: {
                "footer-props": _vm.footer,
                headers: _vm.headers,
                items: _vm.reviews,
                loading: _vm.loading,
                search: _vm.search,
                options: _vm.options,
                dense: "",
                "item-key": "value.id",
                "multi-sort": ""
              },
              on: {
                "update:options": function($event) {
                  _vm.options = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5",
                          attrs: { color: "white", flat: "" }
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "header-label subtitle-2 font-weight-medium text-right mr-2"
                                  },
                                  [_vm._v("funcionário:")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "subtitle-1 text-uppercase" },
                                  [_vm._v(_vm._s(_vm.nomeFuncionario))]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "header-label subtitle-2 font-weight-medium text-right mr-2"
                                  },
                                  [_vm._v("dossiê:")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "subtitle-2 font-weight-light"
                                  },
                                  [_vm._v(_vm._s(_vm.nomeDossie))]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "header-label subtitle-2 font-weight-medium text-right mr-2"
                                  },
                                  [_vm._v("nota:")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "subtitle-2 font-weight-light"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.grade) +
                                        "% (" +
                                        _vm._s(_vm.gradeRaw) +
                                        "%)"
                                    )
                                  ]
                                )
                              ]
                            )
                          ]),
                          _c("v-spacer"),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c("v-select", {
                                  staticClass: "mx-3",
                                  attrs: {
                                    items: _vm.tipos,
                                    clearable: "",
                                    "hide-details": "",
                                    label: "Tipo",
                                    "single-line": ""
                                  },
                                  on: { change: _vm.onFilterChangeEvent },
                                  model: {
                                    value: _vm.searchTipo,
                                    callback: function($$v) {
                                      _vm.searchTipo = $$v
                                    },
                                    expression: "searchTipo"
                                  }
                                }),
                                _c("v-select", {
                                  staticClass: "mx-3",
                                  attrs: {
                                    items: _vm.telas,
                                    clearable: "",
                                    "hide-details": "",
                                    label: "Tela",
                                    "single-line": ""
                                  },
                                  on: { change: _vm.onFilterChangeEvent },
                                  model: {
                                    value: _vm.searchTela,
                                    callback: function($$v) {
                                      _vm.searchTela = $$v
                                    },
                                    expression: "searchTela"
                                  }
                                }),
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.statuses,
                                    clearable: "",
                                    "hide-details": "",
                                    label: "Status",
                                    "single-line": ""
                                  },
                                  on: { change: _vm.onFilterChangeEvent },
                                  model: {
                                    value: _vm.searchStatus,
                                    callback: function($$v) {
                                      _vm.searchStatus = $$v
                                    },
                                    expression: "searchStatus"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex flex-row align-center" },
                              [
                                _c("v-text-field", {
                                  staticClass: "mt-2 ml-3",
                                  attrs: {
                                    "append-icon": "fa-search",
                                    clearable: "",
                                    "hide-details": "",
                                    label: "Pesquisar",
                                    "single-line": ""
                                  },
                                  on: { change: _vm.onFilterChangeEvent },
                                  model: {
                                    value: _vm.search,
                                    callback: function($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item",
                  fn: function({ item }) {
                    return [
                      _c("tr", { class: _vm.rowClass(item) }, [
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "data-table-font data-table-tipo" },
                            [_vm._v(_vm._s(item.tipo))]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "data-table-font data-table-field" },
                            [_vm._v(_vm._s(item.tela))]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "data-table-font data-table-field" },
                            [_vm._v(_vm._s(item.value.field))]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "data-table-font data-table-valor-dossie"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.valueText(item.value.originalValue))
                              )
                            ]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "data-table-font data-table-valor-treinamento"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.valueText(item.value.currentValue))
                              )
                            ]
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _vm.ignored(item.value)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "gray darken-2"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-minus-circle")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "ma-3" }, [
                                      _vm._v(_vm._s(_vm.ignoredTooltipText))
                                    ])
                                  ]
                                )
                              : _vm.correct(item.value)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "green darken-2",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-check")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "ma-3" }, [
                                      _vm._v(_vm._s(_vm.correctTooltipText))
                                    ])
                                  ]
                                )
                              : !_vm.correct(item.value)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "red darken-2",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-times")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "ma-3" }, [
                                      _vm._v(_vm._s(_vm.incorrectTooltipText))
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("v-simple-checkbox", {
                              on: { input: _vm.updateModel },
                              model: {
                                value: item.value.overwritten,
                                callback: function($$v) {
                                  _vm.$set(item.value, "overwritten", $$v)
                                },
                                expression: "item.value.overwritten"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("v-simple-checkbox", {
                              on: { input: _vm.updateModel },
                              model: {
                                value: item.value.overwrittenIgnored,
                                callback: function($$v) {
                                  _vm.$set(
                                    item.value,
                                    "overwrittenIgnored",
                                    $$v
                                  )
                                },
                                expression: "item.value.overwrittenIgnored"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "d-flex justify-center mt-3" },
      [
        _c(
          "v-card",
          {
            staticClass: "pa-1",
            attrs: { outlined: "", tile: "", width: "1267" }
          },
          [
            _c("v-data-table", {
              staticClass: "data-table elevation-0",
              attrs: {
                "footer-props": _vm.footerRespostaAssino,
                headers: _vm.headersRespostaAssino,
                items: _vm.reviewsAssino,
                loading: _vm.loading,
                search: _vm.search,
                options: _vm.optionsRespostaAssino,
                dense: "",
                "item-key": "value.id",
                "multi-sort": ""
              },
              on: {
                "update:options": function($event) {
                  _vm.optionsRespostaAssino = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5",
                          attrs: { color: "white", flat: "" }
                        },
                        [
                          _c("v-spacer", [
                            _c("h1", [_vm._v("Resposta Assino")])
                          ]),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c("div", {
                              staticClass: "d-flex flex-row align-center"
                            })
                          ])
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item",
                  fn: function({ item }) {
                    return [
                      _c("tr", { class: _vm.rowClassRespostaAssino(item) }, [
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "data-table-font data-table-field" },
                            [_vm._v(_vm._s(item.tela))]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            { staticClass: "data-table-font data-table-field" },
                            [_vm._v(_vm._s(item.value.field))]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "data-table-font data-table-valor-dossie"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.valueText(item.value.originalValue))
                              )
                            ]
                          )
                        ]),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "data-table-font data-table-valor-treinamento-resposta-assino"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.valueText(item.value.currentValue))
                              )
                            ]
                          )
                        ]),
                        _c(
                          "td",
                          [
                            _vm.ignored(item.value)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "gray darken-2"
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-minus-circle")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "ma-3" }, [
                                      _vm._v(_vm._s(_vm.ignoredTooltipText))
                                    ])
                                  ]
                                )
                              : _vm.correctRespostaAssino(item.value)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    attrs: {
                                                      color: "green darken-2",
                                                      dark: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v("fa-check")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "ma-3" }, [
                                      _vm._v(_vm._s(_vm.correctTooltipText))
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("v-simple-checkbox", {
                              on: { input: _vm.updateModel },
                              model: {
                                value: item.value.overwritten,
                                callback: function($$v) {
                                  _vm.$set(item.value, "overwritten", $$v)
                                },
                                expression: "item.value.overwritten"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("v-simple-checkbox", {
                              on: { input: _vm.updateModel },
                              model: {
                                value: item.value.overwrittenIgnored,
                                callback: function($$v) {
                                  _vm.$set(
                                    item.value,
                                    "overwrittenIgnored",
                                    $$v
                                  )
                                },
                                expression: "item.value.overwrittenIgnored"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }