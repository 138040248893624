<template>
  <v-menu
    :close-on-content-click="false"
    :max-width="maxWidth"
    :min-width="minWidth"
    offset-y
    ref="menu"
    transition="scale-transition"
    v-model="menu"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        @blur="updateValue(parseDate(dateFormatted))"
        autocomplete="off"
        hint="DD/MM/YYYY"
        id="date-input"
        persistent-hint
        prepend-icon="fa-calendar-alt"
        v-mask="'##/##/####'"
        v-model="dateFormatted"
        v-on="on"
      />
    </template>
    <v-date-picker :max="max" :min="min" :value="value" @input="datePickerInputEvent" no-title />
  </v-menu>
</template>

<script>
export default {
  name: "DateInput",
  props: ["value", "label", "maxWidth", "minWidth", "min", "max"],
  data() {
    return {
      dateFormatted: null,
      menu: false
    };
  },
  computed: {},
  watch: {
    value(val) {
      this.dateFormatted = this.formatDate(val);
    }
  },
  methods: {
    datePickerInputEvent(value) {
      this.menu = false;
      this.updateValue(value);
    },
    updateValue: function(value) {
      this.dateFormatted = this.formatDate(value);
      this.$emit("input", value);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  }
};
</script>

<style scoped></style>
