var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main", attrs: { id: "resumo" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados do Tomador")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CPF\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.avaliacaoRisco.cpf) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [_vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.avaliacaoRisco.nomeCompleto) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("Produtos")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.avaliacaoRisco.produtos.creditoImobiliario,
                          expression:
                            "avaliacaoRisco.produtos.creditoImobiliario"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "creditoImobiliario",
                        name: "creditoImobiliario",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.creditoImobiliario
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.creditoImobiliario,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.creditoImobiliario
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.avaliacaoRisco.produtos.creditoImobiliario,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "creditoImobiliario",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "creditoImobiliario",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "creditoImobiliario",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "creditoImobiliario" } }, [
                    _vm._v("1 - Crédito Imobiliário")
                  ])
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.avaliacaoRisco.produtos.consignacao,
                          expression: "avaliacaoRisco.produtos.consignacao"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "consignacao",
                        name: "consignacao",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.consignacao
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.consignacao,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.consignacao
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.avaliacaoRisco.produtos.consignacao,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "consignacao",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "consignacao",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "consignacao",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "consignacao" } }, [
                    _vm._v("2 - Consignação")
                  ])
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.avaliacaoRisco.produtos.cartaoCredito,
                          expression: "avaliacaoRisco.produtos.cartaoCredito"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "cartaoCredito",
                        name: "cartaoCredito",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.cartaoCredito
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.cartaoCredito,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.cartaoCredito
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.avaliacaoRisco.produtos.cartaoCredito,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "cartaoCredito",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "cartaoCredito",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "cartaoCredito",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "cartaoCredito" } }, [
                    _vm._v("3 - Cartão de Crédito")
                  ])
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.avaliacaoRisco.produtos.contaChequeAzul,
                          expression: "avaliacaoRisco.produtos.contaChequeAzul"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "contaChequeAzul",
                        name: "contaChequeAzul",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.contaChequeAzul
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.contaChequeAzul,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.contaChequeAzul
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.avaliacaoRisco.produtos.contaChequeAzul,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "contaChequeAzul",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "contaChequeAzul",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "contaChequeAzul",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "contaChequeAzul" } }, [
                    _vm._v("4 - Conta com Cheque Azul(CROT)")
                  ])
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.avaliacaoRisco.produtos.consigPortabilidade,
                          expression:
                            "avaliacaoRisco.produtos.consigPortabilidade"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "consigPortabilidade",
                        name: "consigPortabilidade",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.consigPortabilidade
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.consigPortabilidade,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.consigPortabilidade
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.avaliacaoRisco.produtos.consigPortabilidade,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "consigPortabilidade",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "consigPortabilidade",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "consigPortabilidade",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "consigPortabilidade" } }, [
                    _vm._v("9 - Consignação Portabilidade")
                  ])
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.avaliacaoRisco.produtos.chequeAzulIsolado,
                          expression:
                            "avaliacaoRisco.produtos.chequeAzulIsolado"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "chequeAzulIsolado",
                        name: "chequeAzulIsolado",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.chequeAzulIsolado
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.chequeAzulIsolado,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.chequeAzulIsolado
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.avaliacaoRisco.produtos.chequeAzulIsolado,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "chequeAzulIsolado",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "chequeAzulIsolado",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "chequeAzulIsolado",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "chequeAzulIsolado" } }, [
                    _vm._v("10 - Conta com Cheque Azul(CROT)")
                  ])
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("div", { staticClass: "d-flex flex-row align-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.avaliacaoRisco.produtos.chequeAzulIsolado,
                          expression:
                            "avaliacaoRisco.produtos.chequeAzulIsolado"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "chequeAzulIsolado",
                        name: "chequeAzulIsolado",
                        type: "checkbox"
                      },
                      domProps: {
                        checked: Array.isArray(
                          _vm.avaliacaoRisco.produtos.chequeAzulIsolado
                        )
                          ? _vm._i(
                              _vm.avaliacaoRisco.produtos.chequeAzulIsolado,
                              null
                            ) > -1
                          : _vm.avaliacaoRisco.produtos.chequeAzulIsolado
                      },
                      on: {
                        change: function($event) {
                          var $$a =
                              _vm.avaliacaoRisco.produtos.chequeAzulIsolado,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "chequeAzulIsolado",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.avaliacaoRisco.produtos,
                                  "chequeAzulIsolado",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(
                              _vm.avaliacaoRisco.produtos,
                              "chequeAzulIsolado",
                              $$c
                            )
                          }
                        }
                      }
                    })
                  ]),
                  _c("label", { attrs: { for: "chequeAzulIsolado" } }, [
                    _vm._v("11 - Conta com Cheque Azul(CROT)")
                  ])
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Paricipantes")])
          ])
        ],
        1
      ),
      _vm._l(_vm.avaliacaoRisco.participantes, function(item, index) {
        return _c(
          "div",
          { key: item.uid },
          [
            _c(
              "v-row",
              {
                staticClass:
                  "form-row-result resumo-list form-row-result-first",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: _vm.style.colLeftClasses,
                    attrs: {
                      "align-self": _vm.style.alignSelf,
                      cols: _vm.style.colLeft
                    }
                  },
                  [_vm._v("\n        CPF\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: {
                      "align-self": _vm.style.alignSelf,
                      cols: _vm.style.colRight
                    }
                  },
                  [_vm._v("\n        " + _vm._s(item.cpf) + "\n      ")]
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                class: [
                  "form-row-result",
                  "resumo-list",
                  _vm.listItemMargin(index, _vm.avaliacaoRisco.participantes)
                ],
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: _vm.style.colLeftClasses,
                    attrs: {
                      "align-self": _vm.style.alignSelf,
                      cols: _vm.style.colLeft
                    }
                  },
                  [_vm._v("\n        Nome\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: [_vm.style.colRightClasses, "text-uppercase"],
                    attrs: {
                      "align-self": _vm.style.alignSelf,
                      cols: _vm.style.colRight
                    }
                  },
                  [_vm._v("\n        " + _vm._s(item.nome) + "\n      ")]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Informações da Operação")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Origem do Recurso\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.valor) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Modalidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.modalidade) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Você possui convênio?\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.convenio) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Prazo Total (em meses)\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.prazoTotal) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Valor do Imóvel -R$\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.valorImovel) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [
              _vm._v(
                "\n      Prestação necessária para o financiamento - R$\n    "
              )
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.prestNecessaria) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Valor do Financiamento - R$\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.valorFinanciamento) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Garantia\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.garantia) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Portabilidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.portabilidade) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Sistema de Amortização\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.origemRecurso.sistemaAmortizacao) +
                  "\n    "
              )
            ]
          ),
          _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n        Indexador\n      ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.avaliacaoRisco.origemRecurso.indexador) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Autofinanciamento")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CNPJ Construtora\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.avaliacaoRisco.autoFinanciamento.cnpjConstrutora) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Prestações\n    ")]
          ),
          _c(
            "v-col",
            {
              staticClass: "form-col-right align-center pa-0",
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "form-row no-border",
                  attrs: { "no-gutters": "" }
                },
                [
                  _c(
                    "v-col",
                    {
                      class: [
                        ..._vm.style.colLeftClasses,
                        "form-result-label",
                        "form-col-top-custom"
                      ],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                    },
                    [_vm._v("\n          Prestação\n        ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        ..._vm.style.colLeftClasses,
                        "form-result-label",
                        "form-col-top-custom"
                      ],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                    },
                    [_vm._v("\n          Data de Vencimento\n        ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        ..._vm.style.colLeftClasses,
                        "form-result-label",
                        "form-col-top-custom"
                      ],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                    },
                    [_vm._v("\n          Data de Pagamento\n        ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [
                        ..._vm.style.colLeftClasses,
                        "form-result-label",
                        "no-border-right",
                        "form-col-top-custom"
                      ],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [_vm._v("\n          Valor da Prestação\n        ")]
                  )
                ],
                1
              ),
              _vm._l(_vm.prestacoes, function(item) {
                return _c(
                  "v-row",
                  {
                    key: item.numero,
                    class: [
                      "form-row",
                      "no-border",
                      item.numero === 6 ? "no-border-bottom" : ""
                    ],
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.numero) + "\n        "
                        )
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.dataVencimento) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.dataPagamento) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses, "no-border-right"],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.valorPrestacao) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }