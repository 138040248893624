var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "d-flex" }, [
    _c(
      "div",
      { staticClass: "mx-auto" },
      [
        _c(
          "v-row",
          [
            _c("v-col", { attrs: { cols: "12", md: "12" } }, [
              _c(
                "div",
                {
                  staticClass: "text-h4 font-weight-black text-center py-5",
                  staticStyle: { color: "#214498" }
                },
                [
                  _vm._v(
                    "\n          Relatório detalhado dos Treinamentos\n        "
                  )
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { attrs: { cols: "12", md: "2" } },
              [
                _c(
                  "v-subheader",
                  {
                    staticClass: "text-h5 font-weight-black mt-4",
                    staticStyle: { color: "#214498" }
                  },
                  [_vm._v("Período ")]
                )
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "3" } },
              [
                _c("date-input", {
                  staticClass: "mr-4",
                  attrs: { max: _vm.filter.dateTo, label: "De..." },
                  model: {
                    value: _vm.filter.dateFrom,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "dateFrom", $$v)
                    },
                    expression: "filter.dateFrom"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              { attrs: { cols: "12", md: "3" } },
              [
                _c("date-input", {
                  attrs: { min: _vm.filter.dateFrom, label: "Até..." },
                  model: {
                    value: _vm.filter.dateTo,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "dateTo", $$v)
                    },
                    expression: "filter.dateTo"
                  }
                })
              ],
              1
            ),
            _c(
              "v-col",
              {
                class: _vm.searchButtonAlignment,
                attrs: { cols: "12", md: "1" }
              },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-4",
                    attrs: { color: "primary", medium: "" },
                    on: { click: _vm.searchReport }
                  },
                  [_vm._v("\n          Buscar\n        ")]
                )
              ],
              1
            ),
            _c(
              "v-col",
              {
                class: _vm.downloadButtonAlignment,
                attrs: { cols: "12", md: "3" }
              },
              [
                !_vm.exporting
                  ? _c(
                      "download-excel",
                      {
                        staticClass:
                          "mt-4 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          name: "Relatorio_detalhado_Treinamentos.xls",
                          worksheet: "Excel por treinamento",
                          fetch: _vm.exportExcel,
                          "before-generate": _vm.startDownload,
                          "before-finish": _vm.finishDownload,
                          fields: _vm.excelFields,
                          disabled: _vm.exporting
                        }
                      },
                      [
                        _c("span", { staticClass: "v-btn__content" }, [
                          _c("span", [_vm._v("Exportar Excel")])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.exporting
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mt-4",
                        attrs: { color: "primary", medium: "", disabled: "" }
                      },
                      [
                        _c("v-progress-circular", {
                          staticClass: "mr-2",
                          attrs: { indeterminate: "", width: 1, size: 20 }
                        }),
                        _vm._v("\n          Exportando...\n        ")
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-center mt-10 mb-9" },
          [
            _c(
              "v-card",
              { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
              [
                _c("v-data-table", {
                  staticClass: "data-table elevation-0",
                  attrs: {
                    "footer-props": _vm.footer,
                    headers: _vm.headers,
                    "hide-default-footer": true,
                    items: _vm.reportItems,
                    "items-per-page": -1,
                    loading: _vm.loading,
                    search: _vm.search,
                    "item-key": "id"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item.created",
                      fn: function({ item }) {
                        return [
                          _c("span", { staticClass: "table-date-info" }, [
                            _vm._v(_vm._s(_vm.formatDate(item.created)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.completedAt",
                      fn: function({ item }) {
                        return [
                          _c("span", { staticClass: "table-date-info" }, [
                            _vm._v(_vm._s(_vm.formatDate(item.completedAt)))
                          ])
                        ]
                      }
                    },
                    {
                      key: "item.criticalErrorsLabel",
                      fn: function({ item }) {
                        return [
                          item.criticalErrorsLabel
                            ? _c(
                                "v-chip",
                                { attrs: { color: "primary", dark: "" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.criticalErrorsLabel) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    },
                    {
                      key: "item.nonCriticalErrorsLabel",
                      fn: function({ item }) {
                        return [
                          item.nonCriticalErrorsLabel
                            ? _c(
                                "v-chip",
                                { attrs: { color: "primary", dark: "" } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.nonCriticalErrorsLabel) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }