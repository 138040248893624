<template>
  <v-dialog @keydown.esc="closeDialogFunc" fullscreen hide-overlay persistent transition="dialog-bottom-transition"
    v-model="value">
    <v-card>
      <v-toolbar class="primary darken-1" dark fixed>
        <v-toolbar-title class="text-uppercase">Cliente - <span v-for="(fields, index) in cliente" v-if="fields.field === 'cpf'">{{ fields.originalValue }}</span> - 
          <span v-for="(fields, index) in cliente" v-if="fields.field === 'nomeCompleto'">{{ fields.originalValue }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialogFunc" dark icon>
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container>
        <CadastroResumo :cliente="cliente" :dossie="dossie" :show-avaliacao-risco-link="false"
          :to-avaliacao-risco="null"></CadastroResumo>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CadastroResumo from "./CadastroResumo";

export default {
  name: "VisualizarClienteDialog",
  components: { CadastroResumo },
  props: ["value", "cliente", "dossie"],
  data() {
    return {
      visible: false
    };
  },
  methods: {
    updateModel: function (value) {
      this.$emit("input", value);
    },
    closeDialogFunc() {
      this.visible = false;
      this.updateModel(this.visible);
      this.$emit("close");
    }
  }
};
</script>

<style scoped>

</style>
