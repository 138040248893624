var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "d-flex justify-center" },
      [
        _c(
          "v-form",
          { staticClass: "form-size" },
          [
            _c("v-text-field", {
              staticClass: "d-flex",
              attrs: {
                "error-messages": _vm.nomeErrors,
                label: "Nome do Dossiê",
                required: "",
                outlined: "",
                dense: ""
              },
              on: {
                blur: function($event) {
                  return _vm.$v.nome.$touch()
                },
                input: function($event) {
                  return _vm.$v.nome.$touch()
                }
              },
              model: {
                value: _vm.nome,
                callback: function($$v) {
                  _vm.nome = $$v
                },
                expression: "nome"
              }
            }),
            _vm._l(_vm.inputClientes, function(item) {
              return _c("v-text-field", {
                key: item.index,
                staticClass: "input-size",
                attrs: {
                  label: `CPF Cadastro ${item.index + 1} ${
                    item.nome ? "- " + item.nome.toUpperCase() : ""
                  }`,
                  readonly: "",
                  outlined: "",
                  dense: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "append-outer",
                      fn: function() {
                        return [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-3",
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.editCliente(item.cliente)
                                }
                              }
                            },
                            [_vm._v("\n            fa-edit\n          ")]
                          ),
                          _c(
                            "v-icon",
                            {
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteCliente(item.cliente)
                                }
                              }
                            },
                            [_vm._v("\n            fa-trash\n          ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                ),
                model: {
                  value: item.cpf,
                  callback: function($$v) {
                    _vm.$set(item, "cpf", $$v)
                  },
                  expression: "item.cpf"
                }
              })
            }),
            _c("v-text-field", {
              staticClass: "input-size",
              attrs: {
                label: _vm.avaliacaoRiscoLabel,
                dense: "",
                outlined: "",
                readonly: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "append-outer",
                  fn: function() {
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-3",
                          attrs: { small: "" },
                          on: { click: _vm.editAvaliacaoRisco }
                        },
                        [_vm._v("\n            fa-edit\n          ")]
                      ),
                      _c(
                        "v-icon",
                        {
                          attrs: { small: "" },
                          on: { click: _vm.deleteAvaliacaoRisco }
                        },
                        [_vm._v("\n            fa-trash\n          ")]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.avaliacaoRiscoCpf,
                callback: function($$v) {
                  _vm.avaliacaoRiscoCpf = $$v
                },
                expression: "avaliacaoRiscoCpf"
              }
            }),
            _c(
              "v-radio-group",
              {
                attrs: {
                  "error-messages": _vm.statusErrors,
                  label: "Status: ",
                  required: "",
                  row: ""
                },
                on: {
                  blur: function($event) {
                    return _vm.$v.select.$touch()
                  }
                },
                model: {
                  value: _vm.status,
                  callback: function($$v) {
                    _vm.status = $$v
                  },
                  expression: "status"
                }
              },
              [
                _c("v-radio", {
                  attrs: { label: "Rascunho", value: "RASCUNHO" }
                }),
                _c("v-radio", { attrs: { label: "Ativo", value: "ATIVO" } }),
                _c("v-radio", { attrs: { label: "Inativo", value: "INATIVO" } })
              ],
              1
            ),
            _c("v-btn", { staticClass: "mr-4", on: { click: _vm.salvar } }, [
              _vm._v("Salvar")
            ]),
            _c("v-btn", { staticClass: "mr-4", on: { click: _vm.cancelar } }, [
              _vm._v("Cancelar")
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }