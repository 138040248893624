import _ from "lodash";

const telasFieldAR = [
  { nome: "Dados do Tomador", fieldPrefix: "cpf" },
  { nome: "Dados do Tomador", fieldPrefix: "nomeCompleto" },
  { nome: "Produtos", fieldPrefix: "produtos.creditoImobiliario" },
  { nome: "Produtos", fieldPrefix: "produtos.consignacao" },
  { nome: "Produtos", fieldPrefix: "produtos.cartaoCredito" },
  { nome: "Produtos", fieldPrefix: "produtos.chequeEspecial" },
  { nome: "Produtos", fieldPrefix: "produtos.contaChequeAzul" },
  { nome: "Produtos", fieldPrefix: "produtos.consigPortabilidade" },
  { nome: "Produtos", fieldPrefix: "produtos.chequeAzulIsolado" },
  { nome: "Produtos", fieldPrefix: "produtos.creditoRealFacilCaixa" },
  { nome: "Participantes", fieldPrefix: "participantes" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.valor" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.modalidade" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.produto" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.convenio" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.prazoTotal" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.valorImovel" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.prestNecessaria" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.valorFinanciamento" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.garantia" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.portabilidade" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.sistemaAmortizacao" },
  { nome: "Informações da Operação", fieldPrefix: "origemRecurso.indexador" },
  { nome: "Autofinanciamento", fieldPrefix: "autoFinanciamento.cnpjConstrutora" },
  { nome: "Autofinanciamento", fieldPrefix: "autoFinanciamento.prestacoes" }
];

const telaOrder = _.chain(telasFieldAR)
  .map(t => t.nome)
  .uniq()
  .value();

function getTelaOrder(tela) {
  return _.indexOf(telaOrder, tela);
}

function getFiledOrder(record) {
  return _.indexOf(telasFieldAR, record);
}

function getTelaFromFieldPrefix(field) {
  const record = _.find(telasFieldAR, o => _.startsWith(field, o.fieldPrefix));
  if (_.isNil(record)) {
    return { nome: "", fieldPrefix: "", telaOrder: 0, fieldOrder: 0 };
  }
  return { ...record, telaOrder: getTelaOrder(record.nome), fieldOrder: getFiledOrder(record) };
}

export { getTelaFromFieldPrefix as getTelaAvaliacaoRisco };
