var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px", persistent: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function({ on }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    color: "primary",
                                    dark: "",
                                    fab: "",
                                    small: ""
                                  },
                                  on: { click: _vm.showDialogFunc }
                                },
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v("fa-plus")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                },
                [_c("span", [_vm._v("Novo Treinamento")])]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { staticClass: "primary darken-1", attrs: { dark: "" } },
            [
              _c("v-toolbar-title", [_vm._v("Novo Treinamento")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", icon: "" },
                  on: { click: _vm.closeDialogFunc }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("fa-times")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c("v-combobox", {
                    attrs: {
                      items: _vm.dossieNames,
                      value: _vm.value,
                      "append-icon": "",
                      clearable: "",
                      "hide-details": "",
                      "hide-no-data": "",
                      label: "Nome do Dossiê"
                    },
                    on: { input: _vm.updateNomeDossieInput }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-1", outlined: "", small: "" },
                  on: { click: _vm.criarTreinamentoFunc }
                },
                [_vm._v("Criar")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary darken-1", outlined: "", small: "" },
                  on: { click: _vm.criarIniciarTreinamentoFunc }
                },
                [_vm._v("Criar e Iniciar")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }