var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "d-flex justify-center mt-12" },
      [
        _c(
          "v-card",
          { staticClass: "pa-1", attrs: { outlined: "", tile: "" } },
          [
            _c("v-data-table", {
              staticClass: "elevation-0",
              attrs: {
                headers: _vm.headers,
                items: _vm.dossies,
                "item-key": "id",
                "show-select": "",
                search: _vm.search,
                "custom-filter": _vm.filterText
              },
              scopedSlots: _vm._u([
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", color: "white" } },
                        [
                          _c("v-toolbar-title", [_vm._v("DOSSIÊS")]),
                          _c("v-spacer"),
                          _c("v-text-field", {
                            attrs: {
                              "append-icon": "fa-search",
                              "hide-details": "",
                              label: "Pesquisar",
                              "single-line": ""
                            },
                            model: {
                              value: _vm.search,
                              callback: function($$v) {
                                _vm.search = $$v
                              },
                              expression: "search"
                            }
                          }),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                fab: "",
                                dark: "",
                                small: "",
                                color: "primary"
                              },
                              on: { click: _vm.upload }
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("fa-file-excel")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                fab: "",
                                dark: "",
                                small: "",
                                color: "primary"
                              },
                              on: { click: _vm.novo }
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("fa-plus")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-2",
                              attrs: {
                                fab: "",
                                dark: "",
                                small: "",
                                color: "primary"
                              },
                              on: { click: _vm.deleteAll }
                            },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("fa-trash")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.tipoDocumento",
                  fn: function({ item }) {
                    return [
                      item.tipoDocumento
                        ? _c(
                            "v-chip",
                            { attrs: { color: "primary", dark: "" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.tipoDocumento) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.created",
                  fn: function({ item }) {
                    return [
                      _c("span", { staticClass: "table-date-info" }, [
                        _vm._v(_vm._s(_vm.formatDate(item.created)))
                      ])
                    ]
                  }
                },
                {
                  key: "item.action",
                  fn: function({ item }) {
                    return [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-3",
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              return _vm.editItem(item)
                            }
                          }
                        },
                        [_vm._v("\n            fa-edit\n          ")]
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }